/* tslint:disable */
/* eslint-disable */
/**
 * World Rugby Brain Health Service API
 * Back end data storage for Brain Health Service web app
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * A page of Activities
 * @export
 * @interface ActivitiesOnPage
 */
export interface ActivitiesOnPage {
    /**
     * Activities on the page
     * @type {Array<ActivityWithDetail>}
     * @memberof ActivitiesOnPage
     */
    'data': Array<ActivityWithDetail>;
    /**
     * 
     * @type {Paginator}
     * @memberof ActivitiesOnPage
     */
    'paginator': Paginator;
}
/**
 * Something happened for the player that we need to keep a record of
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * Unique id of the activity
     * @type {number}
     * @memberof Activity
     */
    'id': number;
    /**
     * Person this activity belongs to
     * @type {number}
     * @memberof Activity
     */
    'userId': number;
    /**
     * 
     * @type {ActivityType}
     * @memberof Activity
     */
    'activityType': ActivityType;
    /**
     * The id of the message that was sent. eg the email id or the sms id
     * @type {string}
     * @memberof Activity
     */
    'messageId'?: string | null;
    /**
     * A copy of the email sent, or more detail on what happened. In the language of the user
     * @type {string}
     * @memberof Activity
     */
    'notes'?: string | null;
    /**
     * A copy in English (where available) for the sysadmins
     * @type {string}
     * @memberof Activity
     */
    'notesEnglish'?: string | null;
    /**
     * User who created / generated this activity. May be null if this is a system generated activity
     * @type {number}
     * @memberof Activity
     */
    'createdByUserId'?: number | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Activity
     */
    'createdAt': string;
    /**
     * Follow up is required before this date
     * @type {string}
     * @memberof Activity
     */
    'followUpAt'?: string | null;
    /**
     * Follow up action was completed
     * @type {string}
     * @memberof Activity
     */
    'completedAt'?: string | null;
    /**
     * User who completed this activity
     * @type {number}
     * @memberof Activity
     */
    'completedByUserId'?: number | null;
}


/**
 * 
 * @export
 * @interface ActivityQuery
 */
export interface ActivityQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof ActivityQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof ActivityQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof ActivityQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof ActivityQuery
     */
    'sort'?: string;
    /**
     * Show only this player
     * @type {number}
     * @memberof ActivityQuery
     */
    'playerId'?: number;
    /**
     * Show only this organisation
     * @type {number}
     * @memberof ActivityQuery
     */
    'organisationId'?: number;
    /**
     * Show only activities with followUpAt not null and completedAt null
     * @type {boolean}
     * @memberof ActivityQuery
     */
    'needsFollowUp'?: boolean;
}
/**
 * What happened
 * @export
 * @enum {string}
 */

export const ActivityType = {
    note: 'note',
    screeningCreated: 'screeningCreated',
    assessmentCompleted: 'assessmentCompleted',
    assessmentUnCompleted: 'assessmentUnCompleted',
    supporterInviteFailed: 'supporterInviteFailed',
    supporterAccepted: 'supporterAccepted',
    accountApprovalGranted: 'accountApprovalGranted',
    accountApprovalDeclined: 'accountApprovalDeclined',
    emailSent: 'emailSent',
    emailFailed: 'emailFailed',
    smsSent: 'smsSent',
    smsFailed: 'smsFailed'
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];


/**
 * Something happened for the player that we need to keep a record of
 * @export
 * @interface ActivityWithDetail
 */
export interface ActivityWithDetail {
    /**
     * Unique id of the activity
     * @type {number}
     * @memberof ActivityWithDetail
     */
    'id': number;
    /**
     * Person this activity belongs to
     * @type {number}
     * @memberof ActivityWithDetail
     */
    'userId': number;
    /**
     * 
     * @type {ActivityType}
     * @memberof ActivityWithDetail
     */
    'activityType': ActivityType;
    /**
     * The id of the message that was sent. eg the email id or the sms id
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'messageId'?: string | null;
    /**
     * A copy of the email sent, or more detail on what happened. In the language of the user
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'notes'?: string | null;
    /**
     * A copy in English (where available) for the sysadmins
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'notesEnglish'?: string | null;
    /**
     * User who created / generated this activity. May be null if this is a system generated activity
     * @type {number}
     * @memberof ActivityWithDetail
     */
    'createdByUserId'?: number | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'createdAt': string;
    /**
     * Follow up is required before this date
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'followUpAt'?: string | null;
    /**
     * Follow up action was completed
     * @type {string}
     * @memberof ActivityWithDetail
     */
    'completedAt'?: string | null;
    /**
     * User who completed this activity
     * @type {number}
     * @memberof ActivityWithDetail
     */
    'completedByUserId'?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ActivityWithDetail
     */
    'user': User;
    /**
     * 
     * @type {User}
     * @memberof ActivityWithDetail
     */
    'createdByUser'?: User;
    /**
     * 
     * @type {User}
     * @memberof ActivityWithDetail
     */
    'completedByUser'?: User;
}


/**
 * Outcome of the algorithmic assessment
 * @export
 * @enum {string}
 */

export const AlgorithmOutcome = {
    retest: 'retest',
    specialistReview: 'specialistReview',
    followUp: 'followUp',
    education: 'education'
} as const;

export type AlgorithmOutcome = typeof AlgorithmOutcome[keyof typeof AlgorithmOutcome];


/**
 * A self assessment or BHP assessment
 * @export
 * @interface Assessment
 */
export interface Assessment {
    /**
     * Unique id of the assessment
     * @type {number}
     * @memberof Assessment
     */
    'id': number;
    /**
     * Screening this assessment belongs to
     * @type {number}
     * @memberof Assessment
     */
    'screeningId': number;
    /**
     * 
     * @type {AssessmentType}
     * @memberof Assessment
     */
    'assessmentType': AssessmentType;
    /**
     * Names of the steps in this assessment
     * @type {Array<string>}
     * @memberof Assessment
     */
    'steps': Array<string>;
    /**
     * Steps the user has completed
     * @type {Array<string>}
     * @memberof Assessment
     */
    'stepsCompleted': Array<string>;
    /**
     * Aggregated flags from all the steps
     * @type {Array<AssessmentFlag>}
     * @memberof Assessment
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof Assessment
     */
    'algorithmScore'?: number | null;
    /**
     * From the BHP, doctor or specialist
     * @type {string}
     * @memberof Assessment
     */
    'notes'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Assessment
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Assessment
     */
    'updatedAt': string;
    /**
     * Date this assessment was completed. All the steps were finished
     * @type {string}
     * @memberof Assessment
     */
    'completedAt'?: string | null;
}


/**
 * One possible outcome of a questionnaire
 * @export
 * @enum {string}
 */

export const AssessmentFlag = {
    suicide: 'suicide',
    alcohol: 'alcohol',
    drug: 'drug',
    pain: 'pain',
    sleep: 'sleep',
    hearing: 'hearing',
    vision: 'vision',
    fatigue: 'fatigue',
    psych: 'psych',
    depressionLow: 'depressionLow',
    depressionMedium: 'depressionMedium',
    depressionHigh: 'depressionHigh',
    anxietyLow: 'anxietyLow',
    anxietyMedium: 'anxietyMedium',
    anxietyHigh: 'anxietyHigh',
    stressLow: 'stressLow',
    stressMedium: 'stressMedium',
    stressHigh: 'stressHigh',
    cognitiveRiskLow: 'cognitiveRiskLow',
    cognitiveRiskMedium: 'cognitiveRiskMedium',
    cognitiveRiskHigh: 'cognitiveRiskHigh',
    ageRisk: 'ageRisk',
    bmiRisk: 'bmiRisk',
    medicalRisk: 'medicalRisk',
    educationRisk: 'educationRisk',
    dementiaRisk: 'dementiaRisk',
    isolationRisk: 'isolationRisk',
    smokingRisk: 'smokingRisk',
    exerciseRisk: 'exerciseRisk',
    bowelScreening: 'bowelScreening',
    cervicalScreening: 'cervicalScreening',
    breastScreening: 'breastScreening',
    prostateScreening: 'prostateScreening',
    suicideCheckup: 'suicideCheckup',
    iqCodeAbnormal: 'iqCodeAbnormal',
    cognitive2Abnormal: 'cognitive2Abnormal',
    individualRiskAbnormal: 'individualRiskAbnormal'
} as const;

export type AssessmentFlag = typeof AssessmentFlag[keyof typeof AssessmentFlag];


/**
 * 
 * @export
 * @interface AssessmentQuery
 */
export interface AssessmentQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof AssessmentQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof AssessmentQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof AssessmentQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof AssessmentQuery
     */
    'sort'?: string;
    /**
     * Show only this screening
     * @type {number}
     * @memberof AssessmentQuery
     */
    'screeningId'?: number;
    /**
     * 
     * @type {AssessmentType}
     * @memberof AssessmentQuery
     */
    'assessmentType'?: AssessmentType;
    /**
     * Show screenings completed this month eg 2021-01
     * @type {string}
     * @memberof AssessmentQuery
     */
    'completedAt'?: string;
}


/**
 * data from one questionnaire or task completed as part of the player or BHP assessment
 * @export
 * @interface AssessmentStep
 */
export interface AssessmentStep {
    /**
     * Unique id of the assessment item
     * @type {number}
     * @memberof AssessmentStep
     */
    'id': number;
    /**
     * Assessment this item belongs to
     * @type {number}
     * @memberof AssessmentStep
     */
    'assessmentId': number;
    /**
     * Symbolic name of the questionnaire eg mint. This must match the name of a step component in vue and a step.name in the translation file
     * @type {string}
     * @memberof AssessmentStep
     */
    'name': string;
    /**
     * json serialised data generated by the UI
     * @type {string}
     * @memberof AssessmentStep
     */
    'answerJson'?: string | null;
    /**
     * Calculated by the UI from answerJson for display to the medical admin
     * @type {string}
     * @memberof AssessmentStep
     */
    'answerSummary'?: string | null;
    /**
     * Calculated by the UI for each step. Aggregated up to the Assessment by the UI
     * @type {Array<AssessmentFlag>}
     * @memberof AssessmentStep
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof AssessmentStep
     */
    'algorithmScore'?: number | null;
    /**
     * Algorithm calculates score from answers
     * @type {number}
     * @memberof AssessmentStep
     */
    'rawScore'?: number | null;
    /**
     * rawScore adjusted for age, sex, ethnicity and other parameters
     * @type {number}
     * @memberof AssessmentStep
     */
    'adjustedScore'?: number | null;
    /**
     * From the BHP
     * @type {string}
     * @memberof AssessmentStep
     */
    'notes'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof AssessmentStep
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof AssessmentStep
     */
    'updatedAt': string;
    /**
     * Date this item was completed. All the questions were answered
     * @type {string}
     * @memberof AssessmentStep
     */
    'completedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface AssessmentStepsExportQuery
 */
export interface AssessmentStepsExportQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof AssessmentStepsExportQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof AssessmentStepsExportQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof AssessmentStepsExportQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof AssessmentStepsExportQuery
     */
    'sort'?: string;
    /**
     * Get all steps for this organisation
     * @type {number}
     * @memberof AssessmentStepsExportQuery
     */
    'organisationId'?: number;
}
/**
 * A page of Items
 * @export
 * @interface AssessmentStepsOnPage
 */
export interface AssessmentStepsOnPage {
    /**
     * Items on the page
     * @type {Array<AssessmentStep>}
     * @memberof AssessmentStepsOnPage
     */
    'data': Array<AssessmentStep>;
    /**
     * 
     * @type {Paginator}
     * @memberof AssessmentStepsOnPage
     */
    'paginator': Paginator;
}
/**
 * 
 * @export
 * @interface AssessmentStepsQuery
 */
export interface AssessmentStepsQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof AssessmentStepsQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof AssessmentStepsQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof AssessmentStepsQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof AssessmentStepsQuery
     */
    'sort'?: string;
    /**
     * Get all steps for this assessment
     * @type {number}
     * @memberof AssessmentStepsQuery
     */
    'assessmentId'?: number;
}
/**
 * The kind of assessment
 * @export
 * @enum {string}
 */

export const AssessmentType = {
    self: 'self',
    bhp: 'bhp',
    bhpRetest: 'bhpRetest',
    supporter: 'supporter'
} as const;

export type AssessmentType = typeof AssessmentType[keyof typeof AssessmentType];


/**
 * A page of Assessments
 * @export
 * @interface AssessmentsOnPage
 */
export interface AssessmentsOnPage {
    /**
     * Assessments on the page
     * @type {Array<Assessment>}
     * @memberof AssessmentsOnPage
     */
    'data': Array<Assessment>;
    /**
     * 
     * @type {Paginator}
     * @memberof AssessmentsOnPage
     */
    'paginator': Paginator;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * Existing password. We check it before allowing the change
     * @type {string}
     * @memberof ChangePassword
     */
    'oldPassword': string;
    /**
     * The new password
     * @type {string}
     * @memberof ChangePassword
     */
    'newPassword': string;
}
/**
 * A piece of static text / html rendered to users in various contexts
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * Unique id of the content item
     * @type {number}
     * @memberof Content
     */
    'id': number;
    /**
     * Used by UI to select a piece of content to display
     * @type {string}
     * @memberof Content
     */
    'name': string;
    /**
     * ISO language code eg en, en-NZ
     * @type {string}
     * @memberof Content
     */
    'language': string;
    /**
     * Human friendly title
     * @type {string}
     * @memberof Content
     */
    'title': string;
    /**
     * Original content in markdown format
     * @type {string}
     * @memberof Content
     */
    'contentMarkdown': string;
    /**
     * Cache of html rendered from markdown
     * @type {string}
     * @memberof Content
     */
    'contentHtml'?: string;
    /**
     * If true, this content is available to unauthenticated users
     * @type {boolean}
     * @memberof Content
     */
    'isPublic'?: boolean;
    /**
     * Date this was created
     * @type {string}
     * @memberof Content
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Content
     */
    'updatedAt': string;
}
/**
 * A page of Pages
 * @export
 * @interface ContentOnPage
 */
export interface ContentOnPage {
    /**
     * Pages on the page
     * @type {Array<Content>}
     * @memberof ContentOnPage
     */
    'data': Array<Content>;
    /**
     * 
     * @type {Paginator}
     * @memberof ContentOnPage
     */
    'paginator': Paginator;
}
/**
 * A list of tokens that can be embedded into a message
 * @export
 * @interface ContentTokens
 */
export interface ContentTokens {
    /**
     * 
     * @type {string}
     * @memberof ContentTokens
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContentTokens
     */
    'tokens': Array<string>;
}
/**
 * Something happened for the player that we need to keep a record of
 * @export
 * @interface CreateActivity
 */
export interface CreateActivity {
    /**
     * Person this activity belongs to
     * @type {number}
     * @memberof CreateActivity
     */
    'userId': number;
    /**
     * 
     * @type {ActivityType}
     * @memberof CreateActivity
     */
    'activityType': ActivityType;
    /**
     * The id of the message that was sent. eg the email id or the sms id
     * @type {string}
     * @memberof CreateActivity
     */
    'messageId'?: string | null;
    /**
     * A copy of the email sent, or more detail on what happened. In the language of the user
     * @type {string}
     * @memberof CreateActivity
     */
    'notes'?: string | null;
    /**
     * A copy in English (where available) for the sysadmins
     * @type {string}
     * @memberof CreateActivity
     */
    'notesEnglish'?: string | null;
    /**
     * User who created / generated this activity. May be null if this is a system generated activity
     * @type {number}
     * @memberof CreateActivity
     */
    'createdByUserId'?: number | null;
    /**
     * Follow up is required before this date
     * @type {string}
     * @memberof CreateActivity
     */
    'followUpAt'?: string | null;
    /**
     * Follow up action was completed
     * @type {string}
     * @memberof CreateActivity
     */
    'completedAt'?: string | null;
    /**
     * User who completed this activity
     * @type {number}
     * @memberof CreateActivity
     */
    'completedByUserId'?: number | null;
}


/**
 * A self assessment or BHP assessment
 * @export
 * @interface CreateAssessment
 */
export interface CreateAssessment {
    /**
     * Screening this assessment belongs to
     * @type {number}
     * @memberof CreateAssessment
     */
    'screeningId': number;
    /**
     * 
     * @type {AssessmentType}
     * @memberof CreateAssessment
     */
    'assessmentType': AssessmentType;
    /**
     * Names of the steps in this assessment
     * @type {Array<string>}
     * @memberof CreateAssessment
     */
    'steps': Array<string>;
    /**
     * Steps the user has completed
     * @type {Array<string>}
     * @memberof CreateAssessment
     */
    'stepsCompleted': Array<string>;
    /**
     * Aggregated flags from all the steps
     * @type {Array<AssessmentFlag>}
     * @memberof CreateAssessment
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof CreateAssessment
     */
    'algorithmScore'?: number | null;
    /**
     * From the BHP, doctor or specialist
     * @type {string}
     * @memberof CreateAssessment
     */
    'notes'?: string | null;
    /**
     * Date this assessment was completed. All the steps were finished
     * @type {string}
     * @memberof CreateAssessment
     */
    'completedAt'?: string | null;
}


/**
 * data from one questionnaire or task completed as part of the player or BHP assessment
 * @export
 * @interface CreateAssessmentStep
 */
export interface CreateAssessmentStep {
    /**
     * Assessment this item belongs to
     * @type {number}
     * @memberof CreateAssessmentStep
     */
    'assessmentId': number;
    /**
     * Symbolic name of the questionnaire eg mint. This must match the name of a step component in vue and a step.name in the translation file
     * @type {string}
     * @memberof CreateAssessmentStep
     */
    'name': string;
}
/**
 * A piece of static text / html rendered to users in various contexts
 * @export
 * @interface CreateContent
 */
export interface CreateContent {
    /**
     * Used by UI to select a piece of content to display
     * @type {string}
     * @memberof CreateContent
     */
    'name': string;
    /**
     * ISO language code eg en, en-NZ
     * @type {string}
     * @memberof CreateContent
     */
    'language': string;
    /**
     * Human friendly title
     * @type {string}
     * @memberof CreateContent
     */
    'title': string;
    /**
     * Original content in markdown format
     * @type {string}
     * @memberof CreateContent
     */
    'contentMarkdown': string;
    /**
     * If true, this content is available to unauthenticated users
     * @type {boolean}
     * @memberof CreateContent
     */
    'isPublic'?: boolean;
}
/**
 * An organisation
 * @export
 * @interface CreateOrganisation
 */
export interface CreateOrganisation {
    /**
     * Human friendly name eg New Zealand Rugby
     * @type {string}
     * @memberof CreateOrganisation
     */
    'name': string;
    /**
     * Generic email address for the org eg info@nzrugby.org.nz
     * @type {string}
     * @memberof CreateOrganisation
     */
    'email': string;
    /**
     * Generic office phone number for the org eg 0800 123 4567
     * @type {string}
     * @memberof CreateOrganisation
     */
    'phone': string;
    /**
     * Web site
     * @type {string}
     * @memberof CreateOrganisation
     */
    'webSite': string;
    /**
     * eg Pacific/Auckland used to format dates and times when sending meeting reminder emails
     * @type {string}
     * @memberof CreateOrganisation
     */
    'timeZone': string;
    /**
     * Where to find help if player is suicidal. Original content in markdown format
     * @type {string}
     * @memberof CreateOrganisation
     */
    'suicideMarkdown'?: string | null;
    /**
     * Included in emails to players. Original content in markdown format
     * @type {string}
     * @memberof CreateOrganisation
     */
    'playerEmailMarkdown'?: string | null;
    /**
     * Included in emails to family medical practitioners. Original content in markdown format
     * @type {string}
     * @memberof CreateOrganisation
     */
    'fmpEmailMarkdown'?: string | null;
}
/**
 * A player
 * @export
 * @interface CreatePlayer
 */
export interface CreatePlayer {
    /**
     * First name eg John
     * @type {string}
     * @memberof CreatePlayer
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof CreatePlayer
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof CreatePlayer
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof CreatePlayer
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof CreatePlayer
     */
    'organisationId'?: number | null;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof CreatePlayer
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof CreatePlayer
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof CreatePlayer
     */
    'termsAgreedAt'?: string | null;
    /**
     * Primary language the player speaks
     * @type {string}
     * @memberof CreatePlayer
     */
    'preferredLanguage': string | null;
    /**
     * Height of the player
     * @type {number}
     * @memberof CreatePlayer
     */
    'heightCm': number | null;
    /**
     * Weight of the player
     * @type {number}
     * @memberof CreatePlayer
     */
    'weightKg': number | null;
    /**
     * 
     * @type {Sex}
     * @memberof CreatePlayer
     */
    'sex': Sex;
    /**
     * Date of birth
     * @type {string}
     * @memberof CreatePlayer
     */
    'birthDate': string | null;
    /**
     * Years of formal education
     * @type {number}
     * @memberof CreatePlayer
     */
    'educationYears': number | null;
    /**
     * Year retired from professional sport. Null if still playing
     * @type {number}
     * @memberof CreatePlayer
     */
    'yearRetired': number | null;
    /**
     * True if this player was referred here by their national Player Union
     * @type {boolean}
     * @memberof CreatePlayer
     */
    'playerUnionReferred': boolean;
    /**
     * True if this player agreed to have their anonymised data exported for research purposes
     * @type {boolean}
     * @memberof CreatePlayer
     */
    'includeInResearch': boolean;
}


/**
 * An screening that collects assessments and referrals together
 * @export
 * @interface CreateScreening
 */
export interface CreateScreening {
    /**
     * ISO language code that the screening was done in eg en_AU
     * @type {string}
     * @memberof CreateScreening
     */
    'language': string;
    /**
     * Player this screening belongs to
     * @type {number}
     * @memberof CreateScreening
     */
    'playerId': number;
    /**
     * BHP user id
     * @type {number}
     * @memberof CreateScreening
     */
    'bhpAssessorId'?: number | null;
    /**
     * When the BHP assessment will occur
     * @type {string}
     * @memberof CreateScreening
     */
    'bhpMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the meeting
     * @type {string}
     * @memberof CreateScreening
     */
    'bhpMeetingLink'?: string | null;
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof CreateScreening
     */
    'bhpMeetingState'?: string | null;
    /**
     * BHP user id who did the retest
     * @type {number}
     * @memberof CreateScreening
     */
    'bhpRetesterId'?: number | null;
    /**
     * When the BHP retest will occur
     * @type {string}
     * @memberof CreateScreening
     */
    'bhpRetestMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the retest meeting
     * @type {string}
     * @memberof CreateScreening
     */
    'bhpRetestMeetingLink'?: string | null;
    /**
     * Name of support person
     * @type {string}
     * @memberof CreateScreening
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof CreateScreening
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof CreateScreening
     */
    'supporterPhone'?: string | null;
    /**
     * 
     * @type {SupporterRelationship}
     * @memberof CreateScreening
     */
    'supporterRelationship'?: SupporterRelationship;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof CreateScreening
     */
    'algorithmOutcome'?: AlgorithmOutcome;
    /**
     * CTM user id
     * @type {number}
     * @memberof CreateScreening
     */
    'ctmReviewerId'?: number | null;
    /**
     * Date the CTM completed their review
     * @type {string}
     * @memberof CreateScreening
     */
    'ctmReviewedAt'?: string | null;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof CreateScreening
     */
    'ctmReviewOutcome'?: AlgorithmOutcome;
    /**
     * Notes made by the CTM
     * @type {string}
     * @memberof CreateScreening
     */
    'ctmReviewNotes'?: string | null;
    /**
     * Date the doctor viewed this
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorInvited'?: string | null;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof CreateScreening
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof CreateScreening
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof CreateScreening
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof CreateScreening
     */
    'purchaseOrder'?: string | null;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof CreateScreening
     */
    'purchaseOrderRetest'?: string | null;
    /**
     * Date this was marked by an admin as Completed
     * @type {string}
     * @memberof CreateScreening
     */
    'completedAt'?: string | null;
}


/**
 * A list of specialists known to the organisation that FMPs can refer players to
 * @export
 * @interface CreateSpecialist
 */
export interface CreateSpecialist {
    /**
     * Organisation this specialist belongs to
     * @type {number}
     * @memberof CreateSpecialist
     */
    'organisationId': number;
    /**
     * Types of specialist eg Cognitive, Psychological
     * @type {Array<SpecialistType>}
     * @memberof CreateSpecialist
     */
    'specialistTypes'?: Array<SpecialistType>;
    /**
     * Personal name of specialist eg Dr John Smith
     * @type {string}
     * @memberof CreateSpecialist
     */
    'name': string;
    /**
     * Name of the business or practice
     * @type {string}
     * @memberof CreateSpecialist
     */
    'practice'?: string | null;
    /**
     * Email address
     * @type {string}
     * @memberof CreateSpecialist
     */
    'email': string;
    /**
     * Office phone number
     * @type {string}
     * @memberof CreateSpecialist
     */
    'phone': string;
    /**
     * Web site
     * @type {string}
     * @memberof CreateSpecialist
     */
    'webSite'?: string | null;
    /**
     * eg what kind of specialist are they
     * @type {string}
     * @memberof CreateSpecialist
     */
    'notes'?: string | null;
}
/**
 * A user
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * First name eg John
     * @type {string}
     * @memberof CreateUser
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof CreateUser
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof CreateUser
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof CreateUser
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof CreateUser
     */
    'organisationId'?: number | null;
    /**
     * 
     * @type {Role}
     * @memberof CreateUser
     */
    'role': Role;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof CreateUser
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof CreateUser
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof CreateUser
     */
    'termsAgreedAt'?: string | null;
}


/**
 * A cron job / background task
 * @export
 * @interface CronJob
 */
export interface CronJob {
    /**
     * Unique id of the cron job
     * @type {number}
     * @memberof CronJob
     */
    'id': number;
    /**
     * class name used by the dependency injection container
     * @type {string}
     * @memberof CronJob
     */
    'name': string;
    /**
     * Messages emitted by the cron job last time it ran
     * @type {string}
     * @memberof CronJob
     */
    'messages': string | null;
    /**
     * When it last ran
     * @type {string}
     * @memberof CronJob
     */
    'startedAt': string | null;
    /**
     * When it last ran
     * @type {string}
     * @memberof CronJob
     */
    'finishedAt': string | null;
    /**
     * When it will run next
     * @type {string}
     * @memberof CronJob
     */
    'nextRunAt': string | null;
}
/**
 * A page of Cron Jobs
 * @export
 * @interface CronJobsOnPage
 */
export interface CronJobsOnPage {
    /**
     * Cron Jobs on the page
     * @type {Array<CronJob>}
     * @memberof CronJobsOnPage
     */
    'data': Array<CronJob>;
    /**
     * 
     * @type {Paginator}
     * @memberof CronJobsOnPage
     */
    'paginator': Paginator;
}
/**
 * A doctor completes their review, provides optional referral to specialist(s)
 * @export
 * @interface DoctorReview
 */
export interface DoctorReview {
    /**
     * ISO language code that the doctor wants to work in eg en-AU
     * @type {string}
     * @memberof DoctorReview
     */
    'language': string;
    /**
     * An opaque token sent via email. This must be present and match the screening id and player secret
     * @type {string}
     * @memberof DoctorReview
     */
    'token': string;
    /**
     * The secret provided by the player
     * @type {string}
     * @memberof DoctorReview
     */
    'playerSecretCode': string;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof DoctorReview
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof DoctorReview
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof DoctorReview
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
}


/**
 * Bad Request. The data is not valid
 * @export
 * @interface Error400Response
 */
export interface Error400Response {
    /**
     * The http status code
     * @type {number}
     * @memberof Error400Response
     */
    'statusCode'?: number;
    /**
     * A translation key id for the web ui to look ip in meta.api.theId
     * @type {string}
     * @memberof Error400Response
     */
    'messageId'?: string;
    /**
     * String containing more information about the error in case the translation key doesnt exist
     * @type {string}
     * @memberof Error400Response
     */
    'message'?: string;
    /**
     * The fields in the request or response that are invalid, with reasons
     * @type {any}
     * @memberof Error400Response
     */
    'fields'?: any;
}
/**
 * Unauthorised. Please log in
 * @export
 * @interface Error401Response
 */
export interface Error401Response {
    /**
     * The http status code
     * @type {number}
     * @memberof Error401Response
     */
    'statusCode'?: number;
    /**
     * A translation key id for the web ui to look ip in meta.api.theId
     * @type {string}
     * @memberof Error401Response
     */
    'messageId'?: string;
    /**
     * String containing more information about the error in case the translation key doesnt exist
     * @type {string}
     * @memberof Error401Response
     */
    'message'?: string;
    /**
     * The fields in the request or response that are invalid, with reasons
     * @type {any}
     * @memberof Error401Response
     */
    'fields'?: any;
}
/**
 * Forbidden: you do not have permission
 * @export
 * @interface Error403Response
 */
export interface Error403Response {
    /**
     * The http status code
     * @type {number}
     * @memberof Error403Response
     */
    'statusCode'?: number;
    /**
     * A translation key id for the web ui to look ip in meta.api.theId
     * @type {string}
     * @memberof Error403Response
     */
    'messageId'?: string;
    /**
     * String containing more information about the error in case the translation key doesnt exist
     * @type {string}
     * @memberof Error403Response
     */
    'message'?: string;
    /**
     * The fields in the request or response that are invalid, with reasons
     * @type {any}
     * @memberof Error403Response
     */
    'fields'?: any;
}
/**
 * Not Found. The thing does not exist
 * @export
 * @interface Error404Response
 */
export interface Error404Response {
    /**
     * The http status code
     * @type {number}
     * @memberof Error404Response
     */
    'statusCode'?: number;
    /**
     * A translation key id for the web ui to look ip in meta.api.theId
     * @type {string}
     * @memberof Error404Response
     */
    'messageId'?: string;
    /**
     * String containing more information about the error in case the translation key doesnt exist
     * @type {string}
     * @memberof Error404Response
     */
    'message'?: string;
    /**
     * The fields in the request or response that are invalid, with reasons
     * @type {any}
     * @memberof Error404Response
     */
    'fields'?: any;
}
/**
 * An error occurred
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * The http status code
     * @type {number}
     * @memberof ErrorResponse
     */
    'statusCode'?: number;
    /**
     * A translation key id for the web ui to look ip in meta.api.theId
     * @type {string}
     * @memberof ErrorResponse
     */
    'messageId'?: string;
    /**
     * String containing more information about the error in case the translation key doesnt exist
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
    /**
     * The fields in the request or response that are invalid, with reasons
     * @type {any}
     * @memberof ErrorResponse
     */
    'fields'?: any;
}
/**
 * A file that has been uploaded to S3
 * @export
 * @interface FileInformation
 */
export interface FileInformation {
    /**
     * Basename of the file
     * @type {string}
     * @memberof FileInformation
     */
    'name': string;
    /**
     * directory path to the file
     * @type {string}
     * @memberof FileInformation
     */
    'folder': string;
    /**
     * Size of the file in bytes
     * @type {number}
     * @memberof FileInformation
     */
    'size': number;
    /**
     * Date this was uploaded
     * @type {string}
     * @memberof FileInformation
     */
    'createdAt': string;
    /**
     * The file is on the public interwebs
     * @type {string}
     * @memberof FileInformation
     */
    'publicUrl'?: string | null;
    /**
     * A signed url with a short expiry time for downloading the file
     * @type {string}
     * @memberof FileInformation
     */
    'signedUrl'?: string | null;
}
/**
 * A page of Files
 * @export
 * @interface FilesOnPage
 */
export interface FilesOnPage {
    /**
     * Files on the page
     * @type {Array<FileInformation>}
     * @memberof FilesOnPage
     */
    'data': Array<FileInformation>;
    /**
     * 
     * @type {Paginator}
     * @memberof FilesOnPage
     */
    'paginator': Paginator;
}
/**
 * Find many content items that match the filters
 * @export
 * @interface FilterContent
 */
export interface FilterContent {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof FilterContent
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof FilterContent
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof FilterContent
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof FilterContent
     */
    'sort'?: string;
    /**
     * Which language to show. May fallback to english if your requested language does not exist
     * @type {string}
     * @memberof FilterContent
     */
    'language'?: string;
}
/**
 * Find a single content item that matches exactly
 * @export
 * @interface FindContent
 */
export interface FindContent {
    /**
     * Symbolic name of content item
     * @type {string}
     * @memberof FindContent
     */
    'name': string;
    /**
     * Which language to show
     * @type {string}
     * @memberof FindContent
     */
    'language'?: string;
}
/**
 * Please send a new password to this email address
 * @export
 * @interface ForgotPassword
 */
export interface ForgotPassword {
    /**
     * Email address to send new password to
     * @type {string}
     * @memberof ForgotPassword
     */
    'email': string;
}
/**
 * Please can I log in with these credentials?
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Email address for a valid account
     * @type {string}
     * @memberof LoginRequest
     */
    'email': string;
    /**
     * Password that matches the email address
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * You have successfully logged in. Send the accessToken in the Authorisation: Bearer header on each request
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * JWT. Use this as a Bearer token on the Authorisation header
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken': string;
    /**
     * Unix time in milliseconds that this expires
     * @type {number}
     * @memberof LoginResponse
     */
    'accessTokenExpires': number;
    /**
     * JWT. Use this as a Bearer token on the Authorisation header
     * @type {string}
     * @memberof LoginResponse
     */
    'refreshToken': string;
    /**
     * Unix time in milliseconds that this expires
     * @type {number}
     * @memberof LoginResponse
     */
    'refreshTokenExpires': number;
    /**
     * 
     * @type {User}
     * @memberof LoginResponse
     */
    'user': User;
}
/**
 * User has requested login with email and password. Ask for a second factor
 * @export
 * @interface MFAChallenge
 */
export interface MFAChallenge {
    /**
     * User id currently logging in
     * @type {number}
     * @memberof MFAChallenge
     */
    'id': number;
    /**
     * True if the user needs to go through the mfa process
     * @type {boolean}
     * @memberof MFAChallenge
     */
    'mfaRequired': boolean;
    /**
     * A random string that must be sent back in the MFAResponse
     * @type {string}
     * @memberof MFAChallenge
     */
    'mfaToken': string;
}
/**
 * User has provided the second factor
 * @export
 * @interface MFAResponse
 */
export interface MFAResponse {
    /**
     * User id currently logging in
     * @type {number}
     * @memberof MFAResponse
     */
    'id': number;
    /**
     * The random string from the MFAChallenge
     * @type {string}
     * @memberof MFAResponse
     */
    'mfaToken': string;
    /**
     * The 6 digit code from the MFA device provided by the user
     * @type {string}
     * @memberof MFAResponse
     */
    'mfaCode': string;
}
/**
 * Find a single content item by name
 * @export
 * @interface NameContent
 */
export interface NameContent {
    /**
     * Symbolic name of content item
     * @type {string}
     * @memberof NameContent
     */
    'name': string;
}
/**
 * Player is clicking the One Click Login link from email
 * @export
 * @interface OneClickLogin
 */
export interface OneClickLogin {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof OneClickLogin
     */
    'id': number;
    /**
     * Secret token from email to ensure login request is valid
     * @type {string}
     * @memberof OneClickLogin
     */
    'token': string;
}
/**
 * An organisation
 * @export
 * @interface Organisation
 */
export interface Organisation {
    /**
     * Unique id of the organisation
     * @type {number}
     * @memberof Organisation
     */
    'id': number;
    /**
     * Human friendly name eg New Zealand Rugby
     * @type {string}
     * @memberof Organisation
     */
    'name': string;
    /**
     * Generic email address for the org eg info@nzrugby.org.nz
     * @type {string}
     * @memberof Organisation
     */
    'email': string;
    /**
     * Generic office phone number for the org eg 0800 123 4567
     * @type {string}
     * @memberof Organisation
     */
    'phone': string;
    /**
     * Web site
     * @type {string}
     * @memberof Organisation
     */
    'webSite': string;
    /**
     * eg Pacific/Auckland used to format dates and times when sending meeting reminder emails
     * @type {string}
     * @memberof Organisation
     */
    'timeZone': string;
    /**
     * Where to find help if player is suicidal. Original content in markdown format
     * @type {string}
     * @memberof Organisation
     */
    'suicideMarkdown'?: string | null;
    /**
     * Cache of html rendered from suicideMarkdown
     * @type {string}
     * @memberof Organisation
     */
    'suicideHtml': string | null;
    /**
     * Included in emails to players. Original content in markdown format
     * @type {string}
     * @memberof Organisation
     */
    'playerEmailMarkdown'?: string | null;
    /**
     * Cache of html rendered from playerEmailMarkdown
     * @type {string}
     * @memberof Organisation
     */
    'playerEmailHtml': string | null;
    /**
     * Included in emails to family medical practitioners. Original content in markdown format
     * @type {string}
     * @memberof Organisation
     */
    'fmpEmailMarkdown'?: string | null;
    /**
     * Cache of html rendered from fmpEmailMarkdown
     * @type {string}
     * @memberof Organisation
     */
    'fmpEmailHtml': string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Organisation
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Organisation
     */
    'updatedAt': string;
}
/**
 * A page of Organisations
 * @export
 * @interface OrganisationsOnPage
 */
export interface OrganisationsOnPage {
    /**
     * Organisations on the page
     * @type {Array<Organisation>}
     * @memberof OrganisationsOnPage
     */
    'data': Array<Organisation>;
    /**
     * 
     * @type {Paginator}
     * @memberof OrganisationsOnPage
     */
    'paginator': Paginator;
}
/**
 * 
 * @export
 * @interface PaginatedQuery
 */
export interface PaginatedQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof PaginatedQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof PaginatedQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof PaginatedQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof PaginatedQuery
     */
    'sort'?: string;
}
/**
 * The number of items on this page and in the whole data set
 * @export
 * @interface Paginator
 */
export interface Paginator {
    /**
     * The current page we are on. 1..pageCount
     * @type {number}
     * @memberof Paginator
     */
    'page'?: number;
    /**
     * Expected number of items per page
     * @type {number}
     * @memberof Paginator
     */
    'pageSize'?: number;
    /**
     * Number of pages in this result
     * @type {number}
     * @memberof Paginator
     */
    'pageCount': number;
    /**
     * Total number of items in the whole data set
     * @type {number}
     * @memberof Paginator
     */
    'itemCount': number;
}
/**
 * A player
 * @export
 * @interface Player
 */
export interface Player {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof Player
     */
    'id': number;
    /**
     * First name eg John
     * @type {string}
     * @memberof Player
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof Player
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof Player
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof Player
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof Player
     */
    'organisationId'?: number | null;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof Player
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof Player
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof Player
     */
    'termsAgreedAt'?: string | null;
    /**
     * When the user logged in
     * @type {string}
     * @memberof Player
     */
    'lastLoginAt'?: string | null;
    /**
     * When the user last failed to log in (wrong password)
     * @type {string}
     * @memberof Player
     */
    'lastLoginFailedAt'?: string | null;
    /**
     * Number of times the user has failed to log in
     * @type {number}
     * @memberof Player
     */
    'loginFailCount'?: number | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Player
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Player
     */
    'updatedAt': string;
    /**
     * Primary language the player speaks
     * @type {string}
     * @memberof Player
     */
    'preferredLanguage': string | null;
    /**
     * Height of the player
     * @type {number}
     * @memberof Player
     */
    'heightCm': number | null;
    /**
     * Weight of the player
     * @type {number}
     * @memberof Player
     */
    'weightKg': number | null;
    /**
     * 
     * @type {Sex}
     * @memberof Player
     */
    'sex': Sex;
    /**
     * Date of birth
     * @type {string}
     * @memberof Player
     */
    'birthDate': string | null;
    /**
     * Years of formal education
     * @type {number}
     * @memberof Player
     */
    'educationYears': number | null;
    /**
     * Year retired from professional sport. Null if still playing
     * @type {number}
     * @memberof Player
     */
    'yearRetired': number | null;
    /**
     * True if this player was referred here by their national Player Union
     * @type {boolean}
     * @memberof Player
     */
    'playerUnionReferred': boolean;
    /**
     * True if this player agreed to have their anonymised data exported for research purposes
     * @type {boolean}
     * @memberof Player
     */
    'includeInResearch': boolean;
}


/**
 * 
 * @export
 * @interface PlayerQuery
 */
export interface PlayerQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof PlayerQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof PlayerQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof PlayerQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof PlayerQuery
     */
    'sort'?: string;
    /**
     * Show only players in this organisation
     * @type {number}
     * @memberof PlayerQuery
     */
    'organisationId'?: number;
}
/**
 * As a player, I want to create a new account
 * @export
 * @interface PlayerSignup
 */
export interface PlayerSignup {
    /**
     * First name eg John
     * @type {string}
     * @memberof PlayerSignup
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof PlayerSignup
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof PlayerSignup
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof PlayerSignup
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof PlayerSignup
     */
    'organisationId'?: number | null;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof PlayerSignup
     */
    'language': string;
}
/**
 * A player with their most recent screening metadata
 * @export
 * @interface PlayerWithScreening
 */
export interface PlayerWithScreening {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'id': number;
    /**
     * First name eg John
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'organisationId'?: number | null;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'termsAgreedAt'?: string | null;
    /**
     * When the user logged in
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'lastLoginAt'?: string | null;
    /**
     * When the user last failed to log in (wrong password)
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'lastLoginFailedAt'?: string | null;
    /**
     * Number of times the user has failed to log in
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'loginFailCount'?: number | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'updatedAt': string;
    /**
     * Primary language the player speaks
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'preferredLanguage': string | null;
    /**
     * Height of the player
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'heightCm': number | null;
    /**
     * Weight of the player
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'weightKg': number | null;
    /**
     * 
     * @type {Sex}
     * @memberof PlayerWithScreening
     */
    'sex': Sex;
    /**
     * Date of birth
     * @type {string}
     * @memberof PlayerWithScreening
     */
    'birthDate': string | null;
    /**
     * Years of formal education
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'educationYears': number | null;
    /**
     * Year retired from professional sport. Null if still playing
     * @type {number}
     * @memberof PlayerWithScreening
     */
    'yearRetired': number | null;
    /**
     * True if this player was referred here by their national Player Union
     * @type {boolean}
     * @memberof PlayerWithScreening
     */
    'playerUnionReferred': boolean;
    /**
     * True if this player agreed to have their anonymised data exported for research purposes
     * @type {boolean}
     * @memberof PlayerWithScreening
     */
    'includeInResearch': boolean;
    /**
     * 
     * @type {Screening}
     * @memberof PlayerWithScreening
     */
    'screening'?: Screening;
}


/**
 * A page of Players
 * @export
 * @interface PlayersOnPage
 */
export interface PlayersOnPage {
    /**
     * Players on the page
     * @type {Array<Player>}
     * @memberof PlayersOnPage
     */
    'data': Array<Player>;
    /**
     * 
     * @type {Paginator}
     * @memberof PlayersOnPage
     */
    'paginator': Paginator;
}
/**
 * A page of Players
 * @export
 * @interface PlayersWithScreeningOnPage
 */
export interface PlayersWithScreeningOnPage {
    /**
     * Players on the page
     * @type {Array<PlayerWithScreening>}
     * @memberof PlayersWithScreeningOnPage
     */
    'data': Array<PlayerWithScreening>;
    /**
     * 
     * @type {Paginator}
     * @memberof PlayersWithScreeningOnPage
     */
    'paginator': Paginator;
}
/**
 * data from one questionnaire or task completed as part of the player or BHP assessment
 * @export
 * @interface ReferralAssessmentStep
 */
export interface ReferralAssessmentStep {
    /**
     * Symbolic name of the questionnaire eg mint. This must match the name of a step component in vue and a step.name in the translation file
     * @type {string}
     * @memberof ReferralAssessmentStep
     */
    'name': string;
    /**
     * json serialised data generated by the UI
     * @type {string}
     * @memberof ReferralAssessmentStep
     */
    'answerJson'?: string | null;
    /**
     * Algorithm calculates score from answers
     * @type {number}
     * @memberof ReferralAssessmentStep
     */
    'rawScore'?: number | null;
    /**
     * Calculated by the UI from answerJson for display to the medical admin
     * @type {string}
     * @memberof ReferralAssessmentStep
     */
    'answerSummary'?: string | null;
    /**
     * rawScore adjusted for age, sex, ethnicity and other parameters
     * @type {number}
     * @memberof ReferralAssessmentStep
     */
    'adjustedScore'?: number | null;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof ReferralAssessmentStep
     */
    'algorithmScore'?: number | null;
    /**
     * Calculated by the UI for each step. Aggregated up to the Assessment by the UI
     * @type {Array<AssessmentFlag>}
     * @memberof ReferralAssessmentStep
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
}
/**
 * As a doctor or specialist, please can I review this player?
 * @export
 * @interface ReferralRequest
 */
export interface ReferralRequest {
    /**
     * ISO language code that the doctor wants to work in eg en-AU
     * @type {string}
     * @memberof ReferralRequest
     */
    'language': string;
    /**
     * An opaque token sent via email. This must be present and match the screening id and player secret
     * @type {string}
     * @memberof ReferralRequest
     */
    'token': string;
    /**
     * The secret provided by the player
     * @type {string}
     * @memberof ReferralRequest
     */
    'playerSecretCode': string;
}
/**
 * Assessment and player data for the doctor or specialist to review
 * @export
 * @interface ReferralResponse
 */
export interface ReferralResponse {
    /**
     * Unique id of the screening
     * @type {number}
     * @memberof ReferralResponse
     */
    'id': number;
    /**
     * ISO language code that the screening was done in eg en_AU
     * @type {string}
     * @memberof ReferralResponse
     */
    'language': string;
    /**
     * Player this screening belongs to
     * @type {number}
     * @memberof ReferralResponse
     */
    'playerId': number;
    /**
     * The secret given to the player that unlocks doctor and specialist access to the assessment data
     * @type {string}
     * @memberof ReferralResponse
     */
    'playerSecretCode': string;
    /**
     * Date the secret was created / updated
     * @type {string}
     * @memberof ReferralResponse
     */
    'playerSecretChanged': string;
    /**
     * Number of times the wrong player secret was entered since the last time a valid one was used
     * @type {number}
     * @memberof ReferralResponse
     */
    'playerSecretFailCount'?: number;
    /**
     * BHP user id
     * @type {number}
     * @memberof ReferralResponse
     */
    'bhpAssessorId'?: number | null;
    /**
     * When the BHP assessment will occur
     * @type {string}
     * @memberof ReferralResponse
     */
    'bhpMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the meeting
     * @type {string}
     * @memberof ReferralResponse
     */
    'bhpMeetingLink'?: string | null;
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof ReferralResponse
     */
    'bhpMeetingState'?: string | null;
    /**
     * BHP user id who did the retest
     * @type {number}
     * @memberof ReferralResponse
     */
    'bhpRetesterId'?: number | null;
    /**
     * When the BHP retest will occur
     * @type {string}
     * @memberof ReferralResponse
     */
    'bhpRetestMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the retest meeting
     * @type {string}
     * @memberof ReferralResponse
     */
    'bhpRetestMeetingLink'?: string | null;
    /**
     * Name of support person
     * @type {string}
     * @memberof ReferralResponse
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof ReferralResponse
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof ReferralResponse
     */
    'supporterPhone'?: string | null;
    /**
     * 
     * @type {SupporterRelationship}
     * @memberof ReferralResponse
     */
    'supporterRelationship'?: SupporterRelationship;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof ReferralResponse
     */
    'algorithmOutcome'?: AlgorithmOutcome;
    /**
     * CTM user id
     * @type {number}
     * @memberof ReferralResponse
     */
    'ctmReviewerId'?: number | null;
    /**
     * Date the CTM completed their review
     * @type {string}
     * @memberof ReferralResponse
     */
    'ctmReviewedAt'?: string | null;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof ReferralResponse
     */
    'ctmReviewOutcome'?: AlgorithmOutcome;
    /**
     * Notes made by the CTM
     * @type {string}
     * @memberof ReferralResponse
     */
    'ctmReviewNotes'?: string | null;
    /**
     * Date the doctor viewed this
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorInvited'?: string | null;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof ReferralResponse
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof ReferralResponse
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof ReferralResponse
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof ReferralResponse
     */
    'purchaseOrder'?: string | null;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof ReferralResponse
     */
    'purchaseOrderRetest'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof ReferralResponse
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof ReferralResponse
     */
    'updatedAt': string;
    /**
     * Date this was marked by an admin as Completed
     * @type {string}
     * @memberof ReferralResponse
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {Player}
     * @memberof ReferralResponse
     */
    'player': Player;
    /**
     * 
     * @type {Assessment}
     * @memberof ReferralResponse
     */
    'selfAssessment'?: Assessment;
    /**
     * 
     * @type {Assessment}
     * @memberof ReferralResponse
     */
    'supporterAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ReferralResponse
     */
    'bhpAssessor'?: User;
    /**
     * 
     * @type {Assessment}
     * @memberof ReferralResponse
     */
    'bhpAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ReferralResponse
     */
    'bhpRetester'?: User;
    /**
     * 
     * @type {Assessment}
     * @memberof ReferralResponse
     */
    'bhpRetestAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ReferralResponse
     */
    'ctmReviewer'?: User;
    /**
     * Self assessment steps for the doctor or specialist to review
     * @type {Array<ReferralAssessmentStep>}
     * @memberof ReferralResponse
     */
    'selfAssessmentSteps': Array<ReferralAssessmentStep>;
    /**
     * BHP assessment steps for the doctor or specialist to review
     * @type {Array<ReferralAssessmentStep>}
     * @memberof ReferralResponse
     */
    'bhpAssessmentSteps': Array<ReferralAssessmentStep>;
    /**
     * BHP retest steps for the doctor or specialist to review
     * @type {Array<ReferralAssessmentStep>}
     * @memberof ReferralResponse
     */
    'bhpRetestAssessmentSteps': Array<ReferralAssessmentStep>;
    /**
     * Supporter assessment steps for the doctor or specialist to review
     * @type {Array<ReferralAssessmentStep>}
     * @memberof ReferralResponse
     */
    'supporterAssessmentSteps': Array<ReferralAssessmentStep>;
    /**
     * Specialists that the player can be referred to
     * @type {Array<Specialist>}
     * @memberof ReferralResponse
     */
    'specialists': Array<Specialist>;
    /**
     * 
     * @type {Content}
     * @memberof ReferralResponse
     */
    'terms'?: Content;
}


/**
 * Secrets to login as the doctor or supporter
 * @export
 * @interface ReferralSecrets
 */
export interface ReferralSecrets {
    /**
     * Unique id of the screening
     * @type {number}
     * @memberof ReferralSecrets
     */
    'id': number;
    /**
     * Player this screening belongs to
     * @type {number}
     * @memberof ReferralSecrets
     */
    'playerId': number;
    /**
     * The secret given to the player that unlocks doctor and specialist access to the assessment data
     * @type {string}
     * @memberof ReferralSecrets
     */
    'playerSecretCode': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralSecrets
     */
    'doctorToken': string;
    /**
     * 
     * @type {string}
     * @memberof ReferralSecrets
     */
    'supporterToken': string;
}
/**
 * If the registration request is valid then reply with user so they can check their data and set a password
 * @export
 * @interface Registration
 */
export interface Registration {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof Registration
     */
    'id': number;
    /**
     * First name eg John
     * @type {string}
     * @memberof Registration
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof Registration
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof Registration
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof Registration
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof Registration
     */
    'organisationId'?: number | null;
    /**
     * 
     * @type {Role}
     * @memberof Registration
     */
    'role': Role;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof Registration
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof Registration
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof Registration
     */
    'termsAgreedAt'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Registration
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Registration
     */
    'updatedAt': string;
}


/**
 * I forgot my password. Please send me a new one
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * User id we are resetting the password for
     * @type {number}
     * @memberof ResetPassword
     */
    'id': number;
    /**
     * A new password to save for the user
     * @type {string}
     * @memberof ResetPassword
     */
    'password': string;
    /**
     * The password reset token that was included on the link sent in the email
     * @type {string}
     * @memberof ResetPassword
     */
    'token': string;
}
/**
 * User has a role in an organisation
 * @export
 * @enum {string}
 */

export const Role = {
    Player: 'Player',
    Org_Admin: 'Org Admin',
    Brain_Health_Practitioner: 'Brain Health Practitioner',
    Global_Admin: 'Global Admin',
    Clinical_Technical_Manager: 'Clinical Technical Manager'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * Allows uploading a file to a known name and location in S3
 * @export
 * @interface S3FileUpload
 */
export interface S3FileUpload {
    /**
     * 
     * @type {S3FileUploadFields}
     * @memberof S3FileUpload
     */
    'fields': S3FileUploadFields;
    /**
     * The url to POST the file to
     * @type {string}
     * @memberof S3FileUpload
     */
    'url': string;
}
/**
 * These fields must be sent in the POST to the s3 url along with the file content
 * @export
 * @interface S3FileUploadFields
 */
export interface S3FileUploadFields {
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'Policy': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'X-Amz-Algorithm': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'X-Amz-Credential': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'X-Amz-Date': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'X-Amz-Security-Token': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'X-Amz-Signature': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileUploadFields
     */
    'key': string;
}
/**
 * An screening that collects assessments and referrals together
 * @export
 * @interface Screening
 */
export interface Screening {
    /**
     * Unique id of the screening
     * @type {number}
     * @memberof Screening
     */
    'id': number;
    /**
     * ISO language code that the screening was done in eg en_AU
     * @type {string}
     * @memberof Screening
     */
    'language': string;
    /**
     * Player this screening belongs to
     * @type {number}
     * @memberof Screening
     */
    'playerId': number;
    /**
     * The secret given to the player that unlocks doctor and specialist access to the assessment data
     * @type {string}
     * @memberof Screening
     */
    'playerSecretCode': string;
    /**
     * Date the secret was created / updated
     * @type {string}
     * @memberof Screening
     */
    'playerSecretChanged': string;
    /**
     * Number of times the wrong player secret was entered since the last time a valid one was used
     * @type {number}
     * @memberof Screening
     */
    'playerSecretFailCount'?: number;
    /**
     * BHP user id
     * @type {number}
     * @memberof Screening
     */
    'bhpAssessorId'?: number | null;
    /**
     * When the BHP assessment will occur
     * @type {string}
     * @memberof Screening
     */
    'bhpMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the meeting
     * @type {string}
     * @memberof Screening
     */
    'bhpMeetingLink'?: string | null;
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof Screening
     */
    'bhpMeetingState'?: string | null;
    /**
     * BHP user id who did the retest
     * @type {number}
     * @memberof Screening
     */
    'bhpRetesterId'?: number | null;
    /**
     * When the BHP retest will occur
     * @type {string}
     * @memberof Screening
     */
    'bhpRetestMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the retest meeting
     * @type {string}
     * @memberof Screening
     */
    'bhpRetestMeetingLink'?: string | null;
    /**
     * Name of support person
     * @type {string}
     * @memberof Screening
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof Screening
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof Screening
     */
    'supporterPhone'?: string | null;
    /**
     * 
     * @type {SupporterRelationship}
     * @memberof Screening
     */
    'supporterRelationship'?: SupporterRelationship;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof Screening
     */
    'algorithmOutcome'?: AlgorithmOutcome;
    /**
     * CTM user id
     * @type {number}
     * @memberof Screening
     */
    'ctmReviewerId'?: number | null;
    /**
     * Date the CTM completed their review
     * @type {string}
     * @memberof Screening
     */
    'ctmReviewedAt'?: string | null;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof Screening
     */
    'ctmReviewOutcome'?: AlgorithmOutcome;
    /**
     * Notes made by the CTM
     * @type {string}
     * @memberof Screening
     */
    'ctmReviewNotes'?: string | null;
    /**
     * Date the doctor viewed this
     * @type {string}
     * @memberof Screening
     */
    'doctorInvited'?: string | null;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof Screening
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof Screening
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof Screening
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof Screening
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof Screening
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof Screening
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof Screening
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof Screening
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof Screening
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof Screening
     */
    'purchaseOrder'?: string | null;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof Screening
     */
    'purchaseOrderRetest'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Screening
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof Screening
     */
    'updatedAt': string;
    /**
     * Date this was marked by an admin as Completed
     * @type {string}
     * @memberof Screening
     */
    'completedAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ScreeningQuery
 */
export interface ScreeningQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof ScreeningQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof ScreeningQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof ScreeningQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof ScreeningQuery
     */
    'sort'?: string;
    /**
     * Show only this player
     * @type {number}
     * @memberof ScreeningQuery
     */
    'playerId'?: number;
    /**
     * Show only this assessor
     * @type {number}
     * @memberof ScreeningQuery
     */
    'assessorId'?: number;
    /**
     * Show only this organisation
     * @type {number}
     * @memberof ScreeningQuery
     */
    'organisationId'?: number;
    /**
     * Show screenings completed this month eg 2021-01
     * @type {string}
     * @memberof ScreeningQuery
     */
    'completedAt'?: string;
    /**
     * Show screenings with bhp or retest assessments completed this month eg 2021-01
     * @type {string}
     * @memberof ScreeningQuery
     */
    'bhpCompletedAt'?: string;
}
/**
 * An screening that collects assessments and referrals together
 * @export
 * @interface ScreeningState
 */
export interface ScreeningState {
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof ScreeningState
     */
    'bhpMeetingState'?: string | null;
}
/**
 * An screening that collects assessments and referrals together
 * @export
 * @interface ScreeningWithDetail
 */
export interface ScreeningWithDetail {
    /**
     * Unique id of the screening
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'id': number;
    /**
     * ISO language code that the screening was done in eg en_AU
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'language': string;
    /**
     * Player this screening belongs to
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'playerId': number;
    /**
     * The secret given to the player that unlocks doctor and specialist access to the assessment data
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'playerSecretCode': string;
    /**
     * Date the secret was created / updated
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'playerSecretChanged': string;
    /**
     * Number of times the wrong player secret was entered since the last time a valid one was used
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'playerSecretFailCount'?: number;
    /**
     * BHP user id
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'bhpAssessorId'?: number | null;
    /**
     * When the BHP assessment will occur
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'bhpMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the meeting
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'bhpMeetingLink'?: string | null;
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'bhpMeetingState'?: string | null;
    /**
     * BHP user id who did the retest
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'bhpRetesterId'?: number | null;
    /**
     * When the BHP retest will occur
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'bhpRetestMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the retest meeting
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'bhpRetestMeetingLink'?: string | null;
    /**
     * Name of support person
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'supporterPhone'?: string | null;
    /**
     * 
     * @type {SupporterRelationship}
     * @memberof ScreeningWithDetail
     */
    'supporterRelationship'?: SupporterRelationship;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof ScreeningWithDetail
     */
    'algorithmOutcome'?: AlgorithmOutcome;
    /**
     * CTM user id
     * @type {number}
     * @memberof ScreeningWithDetail
     */
    'ctmReviewerId'?: number | null;
    /**
     * Date the CTM completed their review
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'ctmReviewedAt'?: string | null;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof ScreeningWithDetail
     */
    'ctmReviewOutcome'?: AlgorithmOutcome;
    /**
     * Notes made by the CTM
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'ctmReviewNotes'?: string | null;
    /**
     * Date the doctor viewed this
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorInvited'?: string | null;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof ScreeningWithDetail
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof ScreeningWithDetail
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'purchaseOrder'?: string | null;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'purchaseOrderRetest'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'updatedAt': string;
    /**
     * Date this was marked by an admin as Completed
     * @type {string}
     * @memberof ScreeningWithDetail
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {Player}
     * @memberof ScreeningWithDetail
     */
    'player': Player;
    /**
     * 
     * @type {Assessment}
     * @memberof ScreeningWithDetail
     */
    'selfAssessment'?: Assessment;
    /**
     * 
     * @type {Assessment}
     * @memberof ScreeningWithDetail
     */
    'supporterAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ScreeningWithDetail
     */
    'bhpAssessor'?: User;
    /**
     * 
     * @type {Assessment}
     * @memberof ScreeningWithDetail
     */
    'bhpAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ScreeningWithDetail
     */
    'bhpRetester'?: User;
    /**
     * 
     * @type {Assessment}
     * @memberof ScreeningWithDetail
     */
    'bhpRetestAssessment'?: Assessment;
    /**
     * 
     * @type {User}
     * @memberof ScreeningWithDetail
     */
    'ctmReviewer'?: User;
}


/**
 * A page of Screenings
 * @export
 * @interface ScreeningsOnPage
 */
export interface ScreeningsOnPage {
    /**
     * Screenings on the page
     * @type {Array<ScreeningWithDetail>}
     * @memberof ScreeningsOnPage
     */
    'data': Array<ScreeningWithDetail>;
    /**
     * 
     * @type {Paginator}
     * @memberof ScreeningsOnPage
     */
    'paginator': Paginator;
}
/**
 * An organisation
 * @export
 * @interface ServerHealth
 */
export interface ServerHealth {
    /**
     * Environment name eg dev, nonprod, production
     * @type {string}
     * @memberof ServerHealth
     */
    'build': string;
    /**
     * Build number from github action or environment
     * @type {string}
     * @memberof ServerHealth
     */
    'buildNumber': string;
    /**
     * Date of build / release
     * @type {string}
     * @memberof ServerHealth
     */
    'buildDate': string;
    /**
     * database is connected, or error message
     * @type {string}
     * @memberof ServerHealth
     */
    'database': string;
    /**
     * redis is connected, or error message
     * @type {string}
     * @memberof ServerHealth
     */
    'cache': string;
    /**
     * cron jobs are running
     * @type {string}
     * @memberof ServerHealth
     */
    'cron': string;
    /**
     * audit trail is connected, or error message
     * @type {string}
     * @memberof ServerHealth
     */
    'auditTrail': string;
}
/**
 * Biological sex. Needed for scoring assessments
 * @export
 * @enum {string}
 */

export const Sex = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * A list of specialists known to the organisation that FMPs can refer players to
 * @export
 * @interface Specialist
 */
export interface Specialist {
    /**
     * Unique id of the specialist
     * @type {number}
     * @memberof Specialist
     */
    'id': number;
    /**
     * Organisation this specialist belongs to
     * @type {number}
     * @memberof Specialist
     */
    'organisationId': number;
    /**
     * Types of specialist eg Cognitive, Psychological
     * @type {Array<SpecialistType>}
     * @memberof Specialist
     */
    'specialistTypes'?: Array<SpecialistType>;
    /**
     * Personal name of specialist eg Dr John Smith
     * @type {string}
     * @memberof Specialist
     */
    'name': string;
    /**
     * Name of the business or practice
     * @type {string}
     * @memberof Specialist
     */
    'practice'?: string | null;
    /**
     * Email address
     * @type {string}
     * @memberof Specialist
     */
    'email': string;
    /**
     * Office phone number
     * @type {string}
     * @memberof Specialist
     */
    'phone': string;
    /**
     * Web site
     * @type {string}
     * @memberof Specialist
     */
    'webSite'?: string | null;
    /**
     * eg what kind of specialist are they
     * @type {string}
     * @memberof Specialist
     */
    'notes'?: string | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof Specialist
     */
    'createdAt': string;
    /**
     * Date this was updated
     * @type {string}
     * @memberof Specialist
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface SpecialistQuery
 */
export interface SpecialistQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof SpecialistQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof SpecialistQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof SpecialistQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof SpecialistQuery
     */
    'sort'?: string;
    /**
     * Show only this organisation
     * @type {number}
     * @memberof SpecialistQuery
     */
    'organisationId'?: number;
}
/**
 * A list of specialists that the player is currently seeing or was referred to
 * @export
 * @interface SpecialistReferral
 */
export interface SpecialistReferral {
    /**
     * Unique id of the specialist if they are already in the system. Missing id means they are one-off just for this screening
     * @type {number}
     * @memberof SpecialistReferral
     */
    'id'?: number;
    /**
     * 
     * @type {SpecialistType}
     * @memberof SpecialistReferral
     */
    'specialistType': SpecialistType;
    /**
     * Personal name of specialist eg Dr John Smith
     * @type {string}
     * @memberof SpecialistReferral
     */
    'name': string;
    /**
     * Name of the business or practice
     * @type {string}
     * @memberof SpecialistReferral
     */
    'practice'?: string | null;
    /**
     * Email address
     * @type {string}
     * @memberof SpecialistReferral
     */
    'email': string;
    /**
     * Office phone number
     * @type {string}
     * @memberof SpecialistReferral
     */
    'phone': string;
    /**
     * Web site
     * @type {string}
     * @memberof SpecialistReferral
     */
    'webSite'?: string | null;
    /**
     * True if the player added it and is allowed to delete it
     * @type {boolean}
     * @memberof SpecialistReferral
     */
    'addedByPlayer'?: boolean;
    /**
     * Why we referred them to this specialist
     * @type {string}
     * @memberof SpecialistReferral
     */
    'notes'?: string;
    /**
     * Date this was marked Complete by an admin
     * @type {string}
     * @memberof SpecialistReferral
     */
    'completedAt'?: string;
}


/**
 * Whats happening with the specialist referral
 * @export
 * @enum {string}
 */

export const SpecialistReferralStatus = {
    notNeeded: 'notNeeded',
    playerDeclined: 'playerDeclined',
    needsReferral: 'needsReferral'
} as const;

export type SpecialistReferralStatus = typeof SpecialistReferralStatus[keyof typeof SpecialistReferralStatus];


/**
 * Type of specialist
 * @export
 * @enum {string}
 */

export const SpecialistType = {
    cognitive: 'cognitive',
    psychological: 'psychological',
    alcohol: 'alcohol',
    drug: 'drug',
    pain: 'pain',
    sleep: 'sleep',
    other: 'other'
} as const;

export type SpecialistType = typeof SpecialistType[keyof typeof SpecialistType];


/**
 * A page of Specialists
 * @export
 * @interface SpecialistsOnPage
 */
export interface SpecialistsOnPage {
    /**
     * Specialists on the page
     * @type {Array<Specialist>}
     * @memberof SpecialistsOnPage
     */
    'data': Array<Specialist>;
    /**
     * 
     * @type {Paginator}
     * @memberof SpecialistsOnPage
     */
    'paginator': Paginator;
}
/**
 * 
 * @export
 * @interface SupporterAccept
 */
export interface SupporterAccept {
    /**
     * Name of support person
     * @type {string}
     * @memberof SupporterAccept
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof SupporterAccept
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof SupporterAccept
     */
    'supporterPhone'?: string | null;
    /**
     * Token emailed to the supporter
     * @type {string}
     * @memberof SupporterAccept
     */
    'supporterToken': string;
    /**
     * json serialised data generated by the UI
     * @type {string}
     * @memberof SupporterAccept
     */
    'answerJson'?: string | null;
    /**
     * Calculated by the UI from answerJson for display to the medical admin
     * @type {string}
     * @memberof SupporterAccept
     */
    'answerSummary'?: string | null;
    /**
     * Calculated by the UI for each step. Aggregated up to the Assessment by the UI
     * @type {Array<AssessmentFlag>}
     * @memberof SupporterAccept
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
}
/**
 * 
 * @export
 * @interface SupporterDecline
 */
export interface SupporterDecline {
    /**
     * Token emailed to the supporter
     * @type {string}
     * @memberof SupporterDecline
     */
    'supporterToken': string;
}
/**
 * 
 * @export
 * @interface SupporterInformation
 */
export interface SupporterInformation {
    /**
     * Name of support person
     * @type {string}
     * @memberof SupporterInformation
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof SupporterInformation
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof SupporterInformation
     */
    'supporterPhone'?: string | null;
    /**
     * json serialised data generated by the UI
     * @type {string}
     * @memberof SupporterInformation
     */
    'answerJson'?: string | null;
    /**
     * Calculated by the UI from answerJson for display to the medical admin
     * @type {string}
     * @memberof SupporterInformation
     */
    'answerSummary'?: string | null;
    /**
     * Calculated by the UI for each step. Aggregated up to the Assessment by the UI
     * @type {Array<AssessmentFlag>}
     * @memberof SupporterInformation
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * First name eg John
     * @type {string}
     * @memberof SupporterInformation
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof SupporterInformation
     */
    'lastName': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof SupporterInformation
     */
    'organisationId'?: number | null;
}
/**
 * 
 * @export
 * @interface SupporterQuery
 */
export interface SupporterQuery {
    /**
     * Token emailed to the supporter
     * @type {string}
     * @memberof SupporterQuery
     */
    'token': string;
}
/**
 * Relationship of support person to player
 * @export
 * @enum {string}
 */

export const SupporterRelationship = {
    parent: 'parent',
    partner: 'partner',
    sibling: 'sibling',
    child: 'child',
    friend: 'friend',
    other: 'other'
} as const;

export type SupporterRelationship = typeof SupporterRelationship[keyof typeof SupporterRelationship];


/**
 * A self assessment or BHP assessment
 * @export
 * @interface UpdateAssessment
 */
export interface UpdateAssessment {
    /**
     * 
     * @type {AssessmentType}
     * @memberof UpdateAssessment
     */
    'assessmentType'?: AssessmentType;
    /**
     * Names of the steps in this assessment
     * @type {Array<string>}
     * @memberof UpdateAssessment
     */
    'steps'?: Array<string>;
    /**
     * Steps the user has completed
     * @type {Array<string>}
     * @memberof UpdateAssessment
     */
    'stepsCompleted'?: Array<string>;
    /**
     * Aggregated flags from all the steps
     * @type {Array<AssessmentFlag>}
     * @memberof UpdateAssessment
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof UpdateAssessment
     */
    'algorithmScore'?: number | null;
    /**
     * From the BHP, doctor or specialist
     * @type {string}
     * @memberof UpdateAssessment
     */
    'notes'?: string | null;
    /**
     * Date this assessment was completed. All the steps were finished
     * @type {string}
     * @memberof UpdateAssessment
     */
    'completedAt'?: string | null;
}


/**
 * data from one questionnaire or task completed as part of the player or BHP assessment
 * @export
 * @interface UpdateAssessmentStep
 */
export interface UpdateAssessmentStep {
    /**
     * json serialised data generated by the UI
     * @type {string}
     * @memberof UpdateAssessmentStep
     */
    'answerJson'?: string | null;
    /**
     * Calculated by the UI from answerJson for display to the medical admin
     * @type {string}
     * @memberof UpdateAssessmentStep
     */
    'answerSummary'?: string | null;
    /**
     * Calculated by the UI for each step. Aggregated up to the Assessment by the UI
     * @type {Array<AssessmentFlag>}
     * @memberof UpdateAssessmentStep
     */
    'assessmentFlags'?: Array<AssessmentFlag>;
    /**
     * Used to calculate total risk of cognitive decline
     * @type {number}
     * @memberof UpdateAssessmentStep
     */
    'algorithmScore'?: number | null;
    /**
     * Algorithm calculates score from answers
     * @type {number}
     * @memberof UpdateAssessmentStep
     */
    'rawScore'?: number | null;
    /**
     * rawScore adjusted for age, sex, ethnicity and other parameters
     * @type {number}
     * @memberof UpdateAssessmentStep
     */
    'adjustedScore'?: number | null;
    /**
     * From the BHP
     * @type {string}
     * @memberof UpdateAssessmentStep
     */
    'notes'?: string | null;
    /**
     * Date this item was completed. All the questions were answered
     * @type {string}
     * @memberof UpdateAssessmentStep
     */
    'completedAt'?: string | null;
}
/**
 * A piece of static text / html rendered to users in various contexts
 * @export
 * @interface UpdateContent
 */
export interface UpdateContent {
    /**
     * Used by UI to select a piece of content to display
     * @type {string}
     * @memberof UpdateContent
     */
    'name'?: string;
    /**
     * ISO language code eg en, en-NZ
     * @type {string}
     * @memberof UpdateContent
     */
    'language'?: string;
    /**
     * Human friendly title
     * @type {string}
     * @memberof UpdateContent
     */
    'title'?: string;
    /**
     * Original content in markdown format
     * @type {string}
     * @memberof UpdateContent
     */
    'contentMarkdown'?: string;
    /**
     * If true, this content is available to unauthenticated users
     * @type {boolean}
     * @memberof UpdateContent
     */
    'isPublic'?: boolean;
}
/**
 * A cron job / background task
 * @export
 * @interface UpdateCronJob
 */
export interface UpdateCronJob {
    /**
     * When it will run next
     * @type {string}
     * @memberof UpdateCronJob
     */
    'nextRunAt'?: string | null;
}
/**
 * An organisation
 * @export
 * @interface UpdateOrganisation
 */
export interface UpdateOrganisation {
    /**
     * Human friendly name eg New Zealand Rugby
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'name'?: string;
    /**
     * Generic email address for the org eg info@nzrugby.org.nz
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'email'?: string;
    /**
     * Generic office phone number for the org eg 0800 123 4567
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'phone'?: string;
    /**
     * Web site
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'webSite'?: string;
    /**
     * eg Pacific/Auckland used to format dates and times when sending meeting reminder emails
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'timeZone'?: string;
    /**
     * Where to find help if player is suicidal. Original content in markdown format
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'suicideMarkdown'?: string | null;
    /**
     * Included in emails to players. Original content in markdown format
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'playerEmailMarkdown'?: string | null;
    /**
     * Included in emails to family medical practitioners. Original content in markdown format
     * @type {string}
     * @memberof UpdateOrganisation
     */
    'fmpEmailMarkdown'?: string | null;
}
/**
 * A player
 * @export
 * @interface UpdatePlayer
 */
export interface UpdatePlayer {
    /**
     * First name eg John
     * @type {string}
     * @memberof UpdatePlayer
     */
    'firstName'?: string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof UpdatePlayer
     */
    'lastName'?: string;
    /**
     * Personal email address
     * @type {string}
     * @memberof UpdatePlayer
     */
    'email'?: string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof UpdatePlayer
     */
    'phone'?: string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof UpdatePlayer
     */
    'organisationId'?: number | null;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof UpdatePlayer
     */
    'language'?: string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof UpdatePlayer
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof UpdatePlayer
     */
    'termsAgreedAt'?: string | null;
    /**
     * Primary language the player speaks
     * @type {string}
     * @memberof UpdatePlayer
     */
    'preferredLanguage'?: string | null;
    /**
     * Height of the player
     * @type {number}
     * @memberof UpdatePlayer
     */
    'heightCm'?: number | null;
    /**
     * Weight of the player
     * @type {number}
     * @memberof UpdatePlayer
     */
    'weightKg'?: number | null;
    /**
     * 
     * @type {Sex}
     * @memberof UpdatePlayer
     */
    'sex'?: Sex;
    /**
     * Date of birth
     * @type {string}
     * @memberof UpdatePlayer
     */
    'birthDate'?: string | null;
    /**
     * Years of formal education
     * @type {number}
     * @memberof UpdatePlayer
     */
    'educationYears'?: number | null;
    /**
     * Year retired from professional sport. Null if still playing
     * @type {number}
     * @memberof UpdatePlayer
     */
    'yearRetired'?: number | null;
    /**
     * True if this player was referred here by their national Player Union
     * @type {boolean}
     * @memberof UpdatePlayer
     */
    'playerUnionReferred'?: boolean;
    /**
     * True if this player agreed to have their anonymised data exported for research purposes
     * @type {boolean}
     * @memberof UpdatePlayer
     */
    'includeInResearch'?: boolean;
}


/**
 * An screening that collects assessments and referrals together
 * @export
 * @interface UpdateScreening
 */
export interface UpdateScreening {
    /**
     * ISO language code that the screening was done in eg en_AU
     * @type {string}
     * @memberof UpdateScreening
     */
    'language'?: string;
    /**
     * BHP user id
     * @type {number}
     * @memberof UpdateScreening
     */
    'bhpAssessorId'?: number | null;
    /**
     * When the BHP assessment will occur
     * @type {string}
     * @memberof UpdateScreening
     */
    'bhpMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the meeting
     * @type {string}
     * @memberof UpdateScreening
     */
    'bhpMeetingLink'?: string | null;
    /**
     * During the BHP assessment, what image or content is shown to the player
     * @type {string}
     * @memberof UpdateScreening
     */
    'bhpMeetingState'?: string | null;
    /**
     * BHP user id who did the retest
     * @type {number}
     * @memberof UpdateScreening
     */
    'bhpRetesterId'?: number | null;
    /**
     * When the BHP retest will occur
     * @type {string}
     * @memberof UpdateScreening
     */
    'bhpRetestMeetingDate'?: string | null;
    /**
     * Zoom, Teams etc link to join the retest meeting
     * @type {string}
     * @memberof UpdateScreening
     */
    'bhpRetestMeetingLink'?: string | null;
    /**
     * Name of support person
     * @type {string}
     * @memberof UpdateScreening
     */
    'supporterName'?: string | null;
    /**
     * Email of support person
     * @type {string}
     * @memberof UpdateScreening
     */
    'supporterEmail'?: string | null;
    /**
     * Phone of support person
     * @type {string}
     * @memberof UpdateScreening
     */
    'supporterPhone'?: string | null;
    /**
     * 
     * @type {SupporterRelationship}
     * @memberof UpdateScreening
     */
    'supporterRelationship'?: SupporterRelationship;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof UpdateScreening
     */
    'algorithmOutcome'?: AlgorithmOutcome;
    /**
     * CTM user id
     * @type {number}
     * @memberof UpdateScreening
     */
    'ctmReviewerId'?: number | null;
    /**
     * Date the CTM completed their review
     * @type {string}
     * @memberof UpdateScreening
     */
    'ctmReviewedAt'?: string | null;
    /**
     * 
     * @type {AlgorithmOutcome}
     * @memberof UpdateScreening
     */
    'ctmReviewOutcome'?: AlgorithmOutcome;
    /**
     * Notes made by the CTM
     * @type {string}
     * @memberof UpdateScreening
     */
    'ctmReviewNotes'?: string | null;
    /**
     * Date the doctor viewed this
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorInvited'?: string | null;
    /**
     * Name of doctor referred to
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorName'?: string | null;
    /**
     * Email of doctor referred to
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorEmail'?: string | null;
    /**
     * Phone of doctor referred to
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorPhone'?: string | null;
    /**
     * Name of practice of doctor referred to
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorPractice'?: string | null;
    /**
     * Notes made by the doctor (visible to the specialist)
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorNotes'?: string | null;
    /**
     * Date the doctor accepted the terms and conditions
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorTermsAgreed'?: string | null;
    /**
     * Date the doctor finished their review
     * @type {string}
     * @memberof UpdateScreening
     */
    'doctorCompletedAt'?: string | null;
    /**
     * 
     * @type {SpecialistReferralStatus}
     * @memberof UpdateScreening
     */
    'specialistReferralStatus'?: SpecialistReferralStatus;
    /**
     * List of specialists the player is seeing or was referred to
     * @type {Array<SpecialistReferral>}
     * @memberof UpdateScreening
     */
    'specialistReferrals'?: Array<SpecialistReferral>;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof UpdateScreening
     */
    'purchaseOrder'?: string | null;
    /**
     * Purchase order number for paying the Assessor
     * @type {string}
     * @memberof UpdateScreening
     */
    'purchaseOrderRetest'?: string | null;
    /**
     * Date this was marked by an admin as Completed
     * @type {string}
     * @memberof UpdateScreening
     */
    'completedAt'?: string | null;
}


/**
 * A list of specialists known to the organisation that FMPs can refer players to
 * @export
 * @interface UpdateSpecialist
 */
export interface UpdateSpecialist {
    /**
     * Types of specialist eg Cognitive, Psychological
     * @type {Array<SpecialistType>}
     * @memberof UpdateSpecialist
     */
    'specialistTypes'?: Array<SpecialistType>;
    /**
     * Personal name of specialist eg Dr John Smith
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'name'?: string;
    /**
     * Name of the business or practice
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'practice'?: string | null;
    /**
     * Email address
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'email'?: string;
    /**
     * Office phone number
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'phone'?: string;
    /**
     * Web site
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'webSite'?: string | null;
    /**
     * eg what kind of specialist are they
     * @type {string}
     * @memberof UpdateSpecialist
     */
    'notes'?: string | null;
}
/**
 * A user
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * First name eg John
     * @type {string}
     * @memberof UpdateUser
     */
    'firstName'?: string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof UpdateUser
     */
    'lastName'?: string;
    /**
     * Personal email address
     * @type {string}
     * @memberof UpdateUser
     */
    'email'?: string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof UpdateUser
     */
    'phone'?: string;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof UpdateUser
     */
    'language'?: string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof UpdateUser
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof UpdateUser
     */
    'termsAgreedAt'?: string | null;
}
/**
 * A user
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * First name eg John
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * Personal email address
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * The organisation they belong to. May be null for global admins, CTM
     * @type {number}
     * @memberof User
     */
    'organisationId'?: number | null;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    'role': Role;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof User
     */
    'language': string;
    /**
     * When the user was locked out by an administrator
     * @type {string}
     * @memberof User
     */
    'lockedAt'?: string | null;
    /**
     * When the user last agreed to terms and conditions specific to their role, so we know if they need to agree to a new version
     * @type {string}
     * @memberof User
     */
    'termsAgreedAt'?: string | null;
    /**
     * When the user logged in
     * @type {string}
     * @memberof User
     */
    'lastLoginAt'?: string | null;
    /**
     * When the user last failed to log in (wrong password)
     * @type {string}
     * @memberof User
     */
    'lastLoginFailedAt'?: string | null;
    /**
     * Number of times the user has failed to log in
     * @type {number}
     * @memberof User
     */
    'loginFailCount'?: number | null;
    /**
     * Date this was created
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * Date this was last changed
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
}


/**
 * 
 * @export
 * @interface UserQuery
 */
export interface UserQuery {
    /**
     * Which page of results to return (1..n), or 0 for all pages
     * @type {number}
     * @memberof UserQuery
     */
    'page'?: number;
    /**
     * How many items per page
     * @type {number}
     * @memberof UserQuery
     */
    'pageSize'?: number;
    /**
     * Filter the list by things that match this string eg for Players by player name
     * @type {string}
     * @memberof UserQuery
     */
    'search'?: string;
    /**
     * Sort the list by a named set of columns
     * @type {string}
     * @memberof UserQuery
     */
    'sort'?: string;
    /**
     * 
     * @type {Role}
     * @memberof UserQuery
     */
    'role'?: Role;
    /**
     * Show only users in this organisation
     * @type {number}
     * @memberof UserQuery
     */
    'organisationId'?: number;
}


/**
 * User is registering for the first time
 * @export
 * @interface UserRegister
 */
export interface UserRegister {
    /**
     * Unique id of the user
     * @type {number}
     * @memberof UserRegister
     */
    'id': number;
    /**
     * First name eg John
     * @type {string}
     * @memberof UserRegister
     */
    'firstName': string;
    /**
     * Family or last name eg Smith
     * @type {string}
     * @memberof UserRegister
     */
    'lastName': string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof UserRegister
     */
    'phone': string;
    /**
     * Preferred user language code eg en, de-CH
     * @type {string}
     * @memberof UserRegister
     */
    'language': string;
    /**
     * User will login with their email address and this password
     * @type {string}
     * @memberof UserRegister
     */
    'password': string;
    /**
     * Secret token from email to ensure registration request is valid
     * @type {string}
     * @memberof UserRegister
     */
    'token': string;
}
/**
 * A page of Users
 * @export
 * @interface UsersOnPage
 */
export interface UsersOnPage {
    /**
     * Users on the page
     * @type {Array<User>}
     * @memberof UsersOnPage
     */
    'data': Array<User>;
    /**
     * 
     * @type {Paginator}
     * @memberof UsersOnPage
     */
    'paginator': Paginator;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {CreateActivity} [CreateActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity: async (CreateActivity?: CreateActivity, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateActivity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  activitie
         * @summary Delete  activitie
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivityId', 'id', id)
            const localVarPath = `/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * clear the completedAt date
         * @summary clear the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityIdCompleted: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivityIdCompleted', 'id', id)
            const localVarPath = `/activities/{id}/completed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Activities
         * @summary A page of Activities
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [organisationId] Show only this organisation
         * @param {boolean} [needsFollowUp] Show only activities with followUpAt not null and completedAt null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, organisationId?: number, needsFollowUp?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['playerId'] = playerId;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }

            if (needsFollowUp !== undefined) {
                localVarQueryParameter['needsFollowUp'] = needsFollowUp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityId', 'id', id)
            const localVarPath = `/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set the completedAt date
         * @summary set the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityIdCompleted: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityIdCompleted', 'id', id)
            const localVarPath = `/activities/{id}/completed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {CreateActivity} [CreateActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivity(CreateActivity?: CreateActivity, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivity(CreateActivity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.createActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  activitie
         * @summary Delete  activitie
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.deleteActivityId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * clear the completedAt date
         * @summary clear the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityIdCompleted(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityIdCompleted(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.deleteActivityIdCompleted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Activities
         * @summary A page of Activities
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [organisationId] Show only this organisation
         * @param {boolean} [needsFollowUp] Show only activities with followUpAt not null and completedAt null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, organisationId?: number, needsFollowUp?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitiesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(page, pageSize, search, sort, playerId, organisationId, needsFollowUp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.getActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityWithDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.getActivityId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * set the completedAt date
         * @summary set the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityIdCompleted(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityIdCompleted(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.updateActivityIdCompleted']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {CreateActivity} [CreateActivity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivity(CreateActivity?: CreateActivity, options?: any): AxiosPromise<Activity> {
            return localVarFp.createActivity(CreateActivity, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  activitie
         * @summary Delete  activitie
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteActivityId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * clear the completedAt date
         * @summary clear the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityIdCompleted(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteActivityIdCompleted(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Activities
         * @summary A page of Activities
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [organisationId] Show only this organisation
         * @param {boolean} [needsFollowUp] Show only activities with followUpAt not null and completedAt null
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, organisationId?: number, needsFollowUp?: boolean, options?: any): AxiosPromise<ActivitiesOnPage> {
            return localVarFp.getActivity(page, pageSize, search, sort, playerId, organisationId, needsFollowUp, options).then((request) => request(axios, basePath));
        },
        /**
         * Something happened for the player that we need to keep a record of
         * @summary Something happened for the player that we need to keep a record of
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityId(id: number, options?: any): AxiosPromise<ActivityWithDetail> {
            return localVarFp.getActivityId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * set the completedAt date
         * @summary set the completedAt date
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityIdCompleted(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateActivityIdCompleted(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * Something happened for the player that we need to keep a record of
     * @summary Something happened for the player that we need to keep a record of
     * @param {CreateActivity} [CreateActivity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public createActivity(CreateActivity?: CreateActivity, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).createActivity(CreateActivity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  activitie
     * @summary Delete  activitie
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public deleteActivityId(id: number, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).deleteActivityId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * clear the completedAt date
     * @summary clear the completedAt date
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public deleteActivityIdCompleted(id: number, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).deleteActivityIdCompleted(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Activities
     * @summary A page of Activities
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [playerId] Show only this player
     * @param {number} [organisationId] Show only this organisation
     * @param {boolean} [needsFollowUp] Show only activities with followUpAt not null and completedAt null
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivity(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, organisationId?: number, needsFollowUp?: boolean, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivity(page, pageSize, search, sort, playerId, organisationId, needsFollowUp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Something happened for the player that we need to keep a record of
     * @summary Something happened for the player that we need to keep a record of
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivityId(id: number, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getActivityId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set the completedAt date
     * @summary set the completedAt date
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public updateActivityIdCompleted(id: number, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).updateActivityIdCompleted(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {CreateAssessment} [CreateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessments: async (CreateAssessment?: CreateAssessment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAssessment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  assessment
         * @summary Delete  assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAssessmentId', 'id', id)
            const localVarPath = `/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentId', 'id', id)
            const localVarPath = `/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Assessments
         * @summary A page of Assessments
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [screeningId] Show only this screening
         * @param {Array<GetAssessmentsAssessmentType>} [assessmentType] Show only this type of assessment
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessments: async (page?: number, pageSize?: number, search?: string, sort?: string, screeningId?: number, assessmentType?: Array<GetAssessmentsAssessmentType>, completedAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assessments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (screeningId !== undefined) {
                localVarQueryParameter['screeningId'] = screeningId;
            }

            if (assessmentType) {
                localVarQueryParameter['assessmentType'] = assessmentType;
            }

            if (completedAt !== undefined) {
                localVarQueryParameter['completedAt'] = completedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessment} [UpdateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentId: async (id: number, UpdateAssessment?: UpdateAssessment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAssessmentId', 'id', id)
            const localVarPath = `/assessments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAssessment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {CreateAssessment} [CreateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssessments(CreateAssessment?: CreateAssessment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssessments(CreateAssessment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.createAssessments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  assessment
         * @summary Delete  assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.deleteAssessmentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.getAssessmentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Assessments
         * @summary A page of Assessments
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [screeningId] Show only this screening
         * @param {Array<GetAssessmentsAssessmentType>} [assessmentType] Show only this type of assessment
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessments(page?: number, pageSize?: number, search?: string, sort?: string, screeningId?: number, assessmentType?: Array<GetAssessmentsAssessmentType>, completedAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessments(page, pageSize, search, sort, screeningId, assessmentType, completedAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.getAssessments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessment} [UpdateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssessmentId(id: number, UpdateAssessment?: UpdateAssessment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assessment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssessmentId(id, UpdateAssessment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentApi.updateAssessmentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentApiFp(configuration)
    return {
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {CreateAssessment} [CreateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessments(CreateAssessment?: CreateAssessment, options?: any): AxiosPromise<Assessment> {
            return localVarFp.createAssessments(CreateAssessment, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  assessment
         * @summary Delete  assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAssessmentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentId(id: number, options?: any): AxiosPromise<Assessment> {
            return localVarFp.getAssessmentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Assessments
         * @summary A page of Assessments
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [screeningId] Show only this screening
         * @param {Array<GetAssessmentsAssessmentType>} [assessmentType] Show only this type of assessment
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessments(page?: number, pageSize?: number, search?: string, sort?: string, screeningId?: number, assessmentType?: Array<GetAssessmentsAssessmentType>, completedAt?: string, options?: any): AxiosPromise<AssessmentsOnPage> {
            return localVarFp.getAssessments(page, pageSize, search, sort, screeningId, assessmentType, completedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * A self assessment or BHP assessment
         * @summary A self assessment or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessment} [UpdateAssessment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentId(id: number, UpdateAssessment?: UpdateAssessment, options?: any): AxiosPromise<Assessment> {
            return localVarFp.updateAssessmentId(id, UpdateAssessment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
    /**
     * A self assessment or BHP assessment
     * @summary A self assessment or BHP assessment
     * @param {CreateAssessment} [CreateAssessment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public createAssessments(CreateAssessment?: CreateAssessment, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).createAssessments(CreateAssessment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  assessment
     * @summary Delete  assessment
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public deleteAssessmentId(id: number, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).deleteAssessmentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A self assessment or BHP assessment
     * @summary A self assessment or BHP assessment
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public getAssessmentId(id: number, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).getAssessmentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Assessments
     * @summary A page of Assessments
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [screeningId] Show only this screening
     * @param {Array<GetAssessmentsAssessmentType>} [assessmentType] Show only this type of assessment
     * @param {string} [completedAt] Show screenings completed this month eg 2021-01
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public getAssessments(page?: number, pageSize?: number, search?: string, sort?: string, screeningId?: number, assessmentType?: Array<GetAssessmentsAssessmentType>, completedAt?: string, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).getAssessments(page, pageSize, search, sort, screeningId, assessmentType, completedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A self assessment or BHP assessment
     * @summary A self assessment or BHP assessment
     * @param {number} id Unique id of the item
     * @param {UpdateAssessment} [UpdateAssessment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentApi
     */
    public updateAssessmentId(id: number, UpdateAssessment?: UpdateAssessment, options?: RawAxiosRequestConfig) {
        return AssessmentApiFp(this.configuration).updateAssessmentId(id, UpdateAssessment, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAssessmentsAssessmentType = {
    self: 'self',
    bhp: 'bhp',
    bhpRetest: 'bhpRetest',
    supporter: 'supporter'
} as const;
export type GetAssessmentsAssessmentType = typeof GetAssessmentsAssessmentType[keyof typeof GetAssessmentsAssessmentType];


/**
 * AssessmentStepApi - axios parameter creator
 * @export
 */
export const AssessmentStepApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {CreateAssessmentStep} [CreateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentSteps: async (CreateAssessmentStep?: CreateAssessmentStep, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assessment-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateAssessmentStep, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  assessment-step
         * @summary Delete  assessment-step
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentStepId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAssessmentStepId', 'id', id)
            const localVarPath = `/assessment-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentStepId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAssessmentStepId', 'id', id)
            const localVarPath = `/assessment-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Get all steps for this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentStepexport: async (page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assessment-steps/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [assessmentId] Get all steps for this assessment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentSteps: async (page?: number, pageSize?: number, search?: string, sort?: string, assessmentId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assessment-steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (assessmentId !== undefined) {
                localVarQueryParameter['assessmentId'] = assessmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessmentStep} [UpdateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentStepId: async (id: number, UpdateAssessmentStep?: UpdateAssessmentStep, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAssessmentStepId', 'id', id)
            const localVarPath = `/assessment-steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateAssessmentStep, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentStepApi - functional programming interface
 * @export
 */
export const AssessmentStepApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentStepApiAxiosParamCreator(configuration)
    return {
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {CreateAssessmentStep} [CreateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssessmentSteps(CreateAssessmentStep?: CreateAssessmentStep, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssessmentSteps(CreateAssessmentStep, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.createAssessmentSteps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  assessment-step
         * @summary Delete  assessment-step
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssessmentStepId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssessmentStepId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.deleteAssessmentStepId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentStepId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentStepId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.getAssessmentStepId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Get all steps for this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentStepexport(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentStepsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentStepexport(page, pageSize, search, sort, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.getAssessmentStepexport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [assessmentId] Get all steps for this assessment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssessmentSteps(page?: number, pageSize?: number, search?: string, sort?: string, assessmentId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentStepsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessmentSteps(page, pageSize, search, sort, assessmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.getAssessmentSteps']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessmentStep} [UpdateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssessmentStepId(id: number, UpdateAssessmentStep?: UpdateAssessmentStep, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssessmentStepId(id, UpdateAssessmentStep, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentStepApi.updateAssessmentStepId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentStepApi - factory interface
 * @export
 */
export const AssessmentStepApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentStepApiFp(configuration)
    return {
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {CreateAssessmentStep} [CreateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssessmentSteps(CreateAssessmentStep?: CreateAssessmentStep, options?: any): AxiosPromise<AssessmentStep> {
            return localVarFp.createAssessmentSteps(CreateAssessmentStep, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  assessment-step
         * @summary Delete  assessment-step
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssessmentStepId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteAssessmentStepId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentStepId(id: number, options?: any): AxiosPromise<AssessmentStep> {
            return localVarFp.getAssessmentStepId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Get all steps for this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentStepexport(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: any): AxiosPromise<AssessmentStepsOnPage> {
            return localVarFp.getAssessmentStepexport(page, pageSize, search, sort, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Items
         * @summary A page of Items
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [assessmentId] Get all steps for this assessment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssessmentSteps(page?: number, pageSize?: number, search?: string, sort?: string, assessmentId?: number, options?: any): AxiosPromise<AssessmentStepsOnPage> {
            return localVarFp.getAssessmentSteps(page, pageSize, search, sort, assessmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * data from one questionnaire or task completed as part of the player or BHP assessment
         * @summary data from one questionnaire or task completed as part of the player or BHP assessment
         * @param {number} id Unique id of the item
         * @param {UpdateAssessmentStep} [UpdateAssessmentStep] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssessmentStepId(id: number, UpdateAssessmentStep?: UpdateAssessmentStep, options?: any): AxiosPromise<AssessmentStep> {
            return localVarFp.updateAssessmentStepId(id, UpdateAssessmentStep, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentStepApi - object-oriented interface
 * @export
 * @class AssessmentStepApi
 * @extends {BaseAPI}
 */
export class AssessmentStepApi extends BaseAPI {
    /**
     * data from one questionnaire or task completed as part of the player or BHP assessment
     * @summary data from one questionnaire or task completed as part of the player or BHP assessment
     * @param {CreateAssessmentStep} [CreateAssessmentStep] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public createAssessmentSteps(CreateAssessmentStep?: CreateAssessmentStep, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).createAssessmentSteps(CreateAssessmentStep, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  assessment-step
     * @summary Delete  assessment-step
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public deleteAssessmentStepId(id: number, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).deleteAssessmentStepId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * data from one questionnaire or task completed as part of the player or BHP assessment
     * @summary data from one questionnaire or task completed as part of the player or BHP assessment
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public getAssessmentStepId(id: number, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).getAssessmentStepId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Items
     * @summary A page of Items
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [organisationId] Get all steps for this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public getAssessmentStepexport(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).getAssessmentStepexport(page, pageSize, search, sort, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Items
     * @summary A page of Items
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [assessmentId] Get all steps for this assessment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public getAssessmentSteps(page?: number, pageSize?: number, search?: string, sort?: string, assessmentId?: number, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).getAssessmentSteps(page, pageSize, search, sort, assessmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * data from one questionnaire or task completed as part of the player or BHP assessment
     * @summary data from one questionnaire or task completed as part of the player or BHP assessment
     * @param {number} id Unique id of the item
     * @param {UpdateAssessmentStep} [UpdateAssessmentStep] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentStepApi
     */
    public updateAssessmentStepId(id: number, UpdateAssessmentStep?: UpdateAssessmentStep, options?: RawAxiosRequestConfig) {
        return AssessmentStepApiFp(this.configuration).updateAssessmentStepId(id, UpdateAssessmentStep, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * user has lost their password, send them an email with a link to /auth/reset on the UI
         * @summary user has lost their password, send them an email with a link to /auth/reset on the UI
         * @param {ForgotPassword} [ForgotPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthForgot: async (ForgotPassword?: ForgotPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ForgotPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user has entered their email and password and is now being sent an MFA challenge
         * @summary user has entered their email and password and is now being sent an MFA challenge
         * @param {LoginRequest} [LoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogin: async (LoginRequest?: LoginRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(LoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * destroy the session: access and refresh tokens are no longer valid
         * @summary destroy the session: access and refresh tokens are no longer valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user clicked the link in the email, they are immediately logged in
         * @summary user clicked the link in the email, they are immediately logged in
         * @param {OneClickLogin} [OneClickLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthOneClickLogin: async (OneClickLogin?: OneClickLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/one-click-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(OneClickLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * player wants to create a new account
         * @summary player wants to create a new account
         * @param {PlayerSignup} [PlayerSignup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthPlayerSignup: async (PlayerSignup?: PlayerSignup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/player-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(PlayerSignup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user clicked the link in the email, they set a new password and are now logged in
         * @summary user clicked the link in the email, they set a new password and are now logged in
         * @param {UserRegister} [UserRegister] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthRegister: async (UserRegister?: UserRegister, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UserRegister, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user has requested a new MFA code
         * @summary user has requested a new MFA code
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthResendMfa: async (MFAResponse?: MFAResponse, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/resend-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MFAResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user has clicked the link in the email and is now setting a new password
         * @summary user has clicked the link in the email and is now setting a new password
         * @param {ResetPassword} [ResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthReset: async (ResetPassword?: ResetPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ResetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user has entered the 6 digit code from the email or device
         * @summary user has entered the 6 digit code from the email or device
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthVerifyMfa: async (MFAResponse?: MFAResponse, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/verify-mfa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(MFAResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * the access token has expired, send your refresh token to get a new one
         * @summary the access token has expired, send your refresh token to get a new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthRefresh: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * user has been emailed a link to register. They click it and we check the token is valid
         * @summary user has been emailed a link to register. They click it and we check the token is valid
         * @param {number} id User id
         * @param {string} token Secret token to ensure registration request is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthRegister: async (id: number, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuthRegister', 'id', id)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getAuthRegister', 'token', token)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * user has lost their password, send them an email with a link to /auth/reset on the UI
         * @summary user has lost their password, send them an email with a link to /auth/reset on the UI
         * @param {ForgotPassword} [ForgotPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthForgot(ForgotPassword?: ForgotPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthForgot(ForgotPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthForgot']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user has entered their email and password and is now being sent an MFA challenge
         * @summary user has entered their email and password and is now being sent an MFA challenge
         * @param {LoginRequest} [LoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthLogin(LoginRequest?: LoginRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MFAChallenge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthLogin(LoginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * destroy the session: access and refresh tokens are no longer valid
         * @summary destroy the session: access and refresh tokens are no longer valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthLogout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthLogout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthLogout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user clicked the link in the email, they are immediately logged in
         * @summary user clicked the link in the email, they are immediately logged in
         * @param {OneClickLogin} [OneClickLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthOneClickLogin(OneClickLogin?: OneClickLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthOneClickLogin(OneClickLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthOneClickLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * player wants to create a new account
         * @summary player wants to create a new account
         * @param {PlayerSignup} [PlayerSignup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthPlayerSignup(PlayerSignup?: PlayerSignup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthPlayerSignup(PlayerSignup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthPlayerSignup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user clicked the link in the email, they set a new password and are now logged in
         * @summary user clicked the link in the email, they set a new password and are now logged in
         * @param {UserRegister} [UserRegister] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthRegister(UserRegister?: UserRegister, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthRegister(UserRegister, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user has requested a new MFA code
         * @summary user has requested a new MFA code
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthResendMfa(MFAResponse?: MFAResponse, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MFAChallenge>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthResendMfa(MFAResponse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthResendMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user has clicked the link in the email and is now setting a new password
         * @summary user has clicked the link in the email and is now setting a new password
         * @param {ResetPassword} [ResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthReset(ResetPassword?: ResetPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthReset(ResetPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthReset']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user has entered the 6 digit code from the email or device
         * @summary user has entered the 6 digit code from the email or device
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthVerifyMfa(MFAResponse?: MFAResponse, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthVerifyMfa(MFAResponse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.createAuthVerifyMfa']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * the access token has expired, send your refresh token to get a new one
         * @summary the access token has expired, send your refresh token to get a new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthRefresh(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthRefresh(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * user has been emailed a link to register. They click it and we check the token is valid
         * @summary user has been emailed a link to register. They click it and we check the token is valid
         * @param {number} id User id
         * @param {string} token Secret token to ensure registration request is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthRegister(id: number, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Registration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthRegister(id, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.getAuthRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * user has lost their password, send them an email with a link to /auth/reset on the UI
         * @summary user has lost their password, send them an email with a link to /auth/reset on the UI
         * @param {ForgotPassword} [ForgotPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthForgot(ForgotPassword?: ForgotPassword, options?: any): AxiosPromise<string> {
            return localVarFp.createAuthForgot(ForgotPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * user has entered their email and password and is now being sent an MFA challenge
         * @summary user has entered their email and password and is now being sent an MFA challenge
         * @param {LoginRequest} [LoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogin(LoginRequest?: LoginRequest, options?: any): AxiosPromise<MFAChallenge> {
            return localVarFp.createAuthLogin(LoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * destroy the session: access and refresh tokens are no longer valid
         * @summary destroy the session: access and refresh tokens are no longer valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthLogout(options?: any): AxiosPromise<string> {
            return localVarFp.createAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * user clicked the link in the email, they are immediately logged in
         * @summary user clicked the link in the email, they are immediately logged in
         * @param {OneClickLogin} [OneClickLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthOneClickLogin(OneClickLogin?: OneClickLogin, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.createAuthOneClickLogin(OneClickLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * player wants to create a new account
         * @summary player wants to create a new account
         * @param {PlayerSignup} [PlayerSignup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthPlayerSignup(PlayerSignup?: PlayerSignup, options?: any): AxiosPromise<string> {
            return localVarFp.createAuthPlayerSignup(PlayerSignup, options).then((request) => request(axios, basePath));
        },
        /**
         * user clicked the link in the email, they set a new password and are now logged in
         * @summary user clicked the link in the email, they set a new password and are now logged in
         * @param {UserRegister} [UserRegister] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthRegister(UserRegister?: UserRegister, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.createAuthRegister(UserRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * user has requested a new MFA code
         * @summary user has requested a new MFA code
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthResendMfa(MFAResponse?: MFAResponse, options?: any): AxiosPromise<MFAChallenge> {
            return localVarFp.createAuthResendMfa(MFAResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * user has clicked the link in the email and is now setting a new password
         * @summary user has clicked the link in the email and is now setting a new password
         * @param {ResetPassword} [ResetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthReset(ResetPassword?: ResetPassword, options?: any): AxiosPromise<string> {
            return localVarFp.createAuthReset(ResetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * user has entered the 6 digit code from the email or device
         * @summary user has entered the 6 digit code from the email or device
         * @param {MFAResponse} [MFAResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthVerifyMfa(MFAResponse?: MFAResponse, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.createAuthVerifyMfa(MFAResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * the access token has expired, send your refresh token to get a new one
         * @summary the access token has expired, send your refresh token to get a new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthRefresh(options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.getAuthRefresh(options).then((request) => request(axios, basePath));
        },
        /**
         * user has been emailed a link to register. They click it and we check the token is valid
         * @summary user has been emailed a link to register. They click it and we check the token is valid
         * @param {number} id User id
         * @param {string} token Secret token to ensure registration request is valid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthRegister(id: number, token: string, options?: any): AxiosPromise<Registration> {
            return localVarFp.getAuthRegister(id, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * user has lost their password, send them an email with a link to /auth/reset on the UI
     * @summary user has lost their password, send them an email with a link to /auth/reset on the UI
     * @param {ForgotPassword} [ForgotPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthForgot(ForgotPassword?: ForgotPassword, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthForgot(ForgotPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user has entered their email and password and is now being sent an MFA challenge
     * @summary user has entered their email and password and is now being sent an MFA challenge
     * @param {LoginRequest} [LoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthLogin(LoginRequest?: LoginRequest, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthLogin(LoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * destroy the session: access and refresh tokens are no longer valid
     * @summary destroy the session: access and refresh tokens are no longer valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthLogout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user clicked the link in the email, they are immediately logged in
     * @summary user clicked the link in the email, they are immediately logged in
     * @param {OneClickLogin} [OneClickLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthOneClickLogin(OneClickLogin?: OneClickLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthOneClickLogin(OneClickLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * player wants to create a new account
     * @summary player wants to create a new account
     * @param {PlayerSignup} [PlayerSignup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthPlayerSignup(PlayerSignup?: PlayerSignup, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthPlayerSignup(PlayerSignup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user clicked the link in the email, they set a new password and are now logged in
     * @summary user clicked the link in the email, they set a new password and are now logged in
     * @param {UserRegister} [UserRegister] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthRegister(UserRegister?: UserRegister, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthRegister(UserRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user has requested a new MFA code
     * @summary user has requested a new MFA code
     * @param {MFAResponse} [MFAResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthResendMfa(MFAResponse?: MFAResponse, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthResendMfa(MFAResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user has clicked the link in the email and is now setting a new password
     * @summary user has clicked the link in the email and is now setting a new password
     * @param {ResetPassword} [ResetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthReset(ResetPassword?: ResetPassword, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthReset(ResetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user has entered the 6 digit code from the email or device
     * @summary user has entered the 6 digit code from the email or device
     * @param {MFAResponse} [MFAResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createAuthVerifyMfa(MFAResponse?: MFAResponse, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).createAuthVerifyMfa(MFAResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * the access token has expired, send your refresh token to get a new one
     * @summary the access token has expired, send your refresh token to get a new one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthRefresh(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthRefresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * user has been emailed a link to register. They click it and we check the token is valid
     * @summary user has been emailed a link to register. They click it and we check the token is valid
     * @param {number} id User id
     * @param {string} token Secret token to ensure registration request is valid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthRegister(id: number, token: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).getAuthRegister(id, token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {CreateContent} [CreateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: async (CreateContent?: CreateContent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  content
         * @summary Delete  content
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContentId', 'id', id)
            const localVarPath = `/content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  content file
         * @summary Delete  content file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentIdFileName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContentIdFileName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteContentIdFileName', 'name', name)
            const localVarPath = `/content/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Pages
         * @summary A page of Pages
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {string} [language] Which language to show. May fallback to english if your requested language does not exist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: async (page?: number, pageSize?: number, search?: string, sort?: string, language?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * find a single piece of content by language and name
         * @summary find a single piece of content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFindNameLanguage: async (name: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getContentFindNameLanguage', 'name', name)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getContentFindNameLanguage', 'language', language)
            const localVarPath = `/content/find/{name}/{language}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * find a single piece of public content by language and name
         * @summary find a single piece of public content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFindPublicNameLanguage: async (name: string, language: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getContentFindPublicNameLanguage', 'name', name)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getContentFindPublicNameLanguage', 'language', language)
            const localVarPath = `/content/find-public/{name}/{language}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContentId', 'id', id)
            const localVarPath = `/content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFileName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContentIdFileName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getContentIdFileName', 'name', name)
            const localVarPath = `/content/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFiles: async (id: number, page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContentIdFiles', 'id', id)
            const localVarPath = `/content/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFilesPublic: async (id: number, page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContentIdFilesPublic', 'id', id)
            const localVarPath = `/content/{id}/files-public`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * a list of tokens that can be embedded into the content eg as email template values
         * @summary a list of tokens that can be embedded into the content eg as email template values
         * @param {string} name Symbolic name of content item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentTokenName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getContentTokenName', 'name', name)
            const localVarPath = `/content/tokens/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceContentIdFileName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceContentIdFileName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceContentIdFileName', 'name', name)
            const localVarPath = `/content/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {UpdateContent} [UpdateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentId: async (id: number, UpdateContent?: UpdateContent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContentId', 'id', id)
            const localVarPath = `/content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {CreateContent} [CreateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContent(CreateContent?: CreateContent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContent(CreateContent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.createContent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  content
         * @summary Delete  content
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.deleteContentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  content file
         * @summary Delete  content file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContentIdFileName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.deleteContentIdFileName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Pages
         * @summary A page of Pages
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {string} [language] Which language to show. May fallback to english if your requested language does not exist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContent(page?: number, pageSize?: number, search?: string, sort?: string, language?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContent(page, pageSize, search, sort, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * find a single piece of content by language and name
         * @summary find a single piece of content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentFindNameLanguage(name: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentFindNameLanguage(name, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentFindNameLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * find a single piece of public content by language and name
         * @summary find a single piece of public content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentFindPublicNameLanguage(name: string, language: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentFindPublicNameLanguage(name, language, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentFindPublicNameLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentIdFileName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentIdFileName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentIdFiles(id, page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentIdFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentIdFilesPublic(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentIdFilesPublic(id, page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentIdFilesPublic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * a list of tokens that can be embedded into the content eg as email template values
         * @summary a list of tokens that can be embedded into the content eg as email template values
         * @param {string} name Symbolic name of content item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentTokenName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentTokenName(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.getContentTokenName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceContentIdFileName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.replaceContentIdFileName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {UpdateContent} [UpdateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContentId(id: number, UpdateContent?: UpdateContent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContentId(id, UpdateContent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContentApi.updateContentId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {CreateContent} [CreateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent(CreateContent?: CreateContent, options?: any): AxiosPromise<Content> {
            return localVarFp.createContent(CreateContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  content
         * @summary Delete  content
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteContentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  content file
         * @summary Delete  content file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentIdFileName(id: number, name: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteContentIdFileName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Pages
         * @summary A page of Pages
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {string} [language] Which language to show. May fallback to english if your requested language does not exist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent(page?: number, pageSize?: number, search?: string, sort?: string, language?: string, options?: any): AxiosPromise<ContentOnPage> {
            return localVarFp.getContent(page, pageSize, search, sort, language, options).then((request) => request(axios, basePath));
        },
        /**
         * find a single piece of content by language and name
         * @summary find a single piece of content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFindNameLanguage(name: string, language: string, options?: any): AxiosPromise<Content> {
            return localVarFp.getContentFindNameLanguage(name, language, options).then((request) => request(axios, basePath));
        },
        /**
         * find a single piece of public content by language and name
         * @summary find a single piece of public content by language and name
         * @param {string} name Symbolic name of content item
         * @param {string} language Which language to show
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentFindPublicNameLanguage(name: string, language: string, options?: any): AxiosPromise<Content> {
            return localVarFp.getContentFindPublicNameLanguage(name, language, options).then((request) => request(axios, basePath));
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentId(id: number, options?: any): AxiosPromise<Content> {
            return localVarFp.getContentId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFileName(id: number, name: string, options?: any): AxiosPromise<FileInformation> {
            return localVarFp.getContentIdFileName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<FilesOnPage> {
            return localVarFp.getContentIdFiles(id, page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentIdFilesPublic(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<FilesOnPage> {
            return localVarFp.getContentIdFilesPublic(id, page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * a list of tokens that can be embedded into the content eg as email template values
         * @summary a list of tokens that can be embedded into the content eg as email template values
         * @param {string} name Symbolic name of content item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentTokenName(name: string, options?: any): AxiosPromise<ContentTokens> {
            return localVarFp.getContentTokenName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceContentIdFileName(id: number, name: string, options?: any): AxiosPromise<S3FileUpload> {
            return localVarFp.replaceContentIdFileName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * A piece of static text / html rendered to users in various contexts
         * @summary A piece of static text / html rendered to users in various contexts
         * @param {number} id Unique id of the item
         * @param {UpdateContent} [UpdateContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContentId(id: number, UpdateContent?: UpdateContent, options?: any): AxiosPromise<Content> {
            return localVarFp.updateContentId(id, UpdateContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * A piece of static text / html rendered to users in various contexts
     * @summary A piece of static text / html rendered to users in various contexts
     * @param {CreateContent} [CreateContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public createContent(CreateContent?: CreateContent, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).createContent(CreateContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  content
     * @summary Delete  content
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteContentId(id: number, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).deleteContentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  content file
     * @summary Delete  content file
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).deleteContentIdFileName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Pages
     * @summary A page of Pages
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {string} [language] Which language to show. May fallback to english if your requested language does not exist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContent(page?: number, pageSize?: number, search?: string, sort?: string, language?: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContent(page, pageSize, search, sort, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * find a single piece of content by language and name
     * @summary find a single piece of content by language and name
     * @param {string} name Symbolic name of content item
     * @param {string} language Which language to show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentFindNameLanguage(name: string, language: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentFindNameLanguage(name, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * find a single piece of public content by language and name
     * @summary find a single piece of public content by language and name
     * @param {string} name Symbolic name of content item
     * @param {string} language Which language to show
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentFindPublicNameLanguage(name: string, language: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentFindPublicNameLanguage(name, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A piece of static text / html rendered to users in various contexts
     * @summary A piece of static text / html rendered to users in various contexts
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentId(id: number, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A file that has been uploaded to S3
     * @summary A file that has been uploaded to S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentIdFileName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Files
     * @summary A page of Files
     * @param {number} id Unique id of the item
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentIdFiles(id, page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Files
     * @summary A page of Files
     * @param {number} id Unique id of the item
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentIdFilesPublic(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentIdFilesPublic(id, page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * a list of tokens that can be embedded into the content eg as email template values
     * @summary a list of tokens that can be embedded into the content eg as email template values
     * @param {string} name Symbolic name of content item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentTokenName(name: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).getContentTokenName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows uploading a file to a known name and location in S3
     * @summary Allows uploading a file to a known name and location in S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public replaceContentIdFileName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).replaceContentIdFileName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A piece of static text / html rendered to users in various contexts
     * @summary A piece of static text / html rendered to users in various contexts
     * @param {number} id Unique id of the item
     * @param {UpdateContent} [UpdateContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public updateContentId(id: number, UpdateContent?: UpdateContent, options?: RawAxiosRequestConfig) {
        return ContentApiFp(this.configuration).updateContentId(id, UpdateContent, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * An organisation
         * @summary An organisation
         * @param {CreateOrganisation} [CreateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisations: async (CreateOrganisation?: CreateOrganisation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateOrganisation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  organisation
         * @summary Delete  organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisationId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrganisationId', 'id', id)
            const localVarPath = `/organisations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganisationId', 'id', id)
            const localVarPath = `/organisations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Organisations
         * @summary A page of Organisations
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisations: async (page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/organisations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {UpdateOrganisation} [UpdateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisationId: async (id: number, UpdateOrganisation?: UpdateOrganisation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganisationId', 'id', id)
            const localVarPath = `/organisations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateOrganisation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * An organisation
         * @summary An organisation
         * @param {CreateOrganisation} [CreateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganisations(CreateOrganisation?: CreateOrganisation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganisations(CreateOrganisation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationApi.createOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  organisation
         * @summary Delete  organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganisationId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganisationId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationApi.deleteOrganisationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisationId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisationId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationApi.getOrganisationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Organisations
         * @summary A page of Organisations
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganisations(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganisations(page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationApi.getOrganisations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {UpdateOrganisation} [UpdateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganisationId(id: number, UpdateOrganisation?: UpdateOrganisation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organisation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganisationId(id, UpdateOrganisation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganisationApi.updateOrganisationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * An organisation
         * @summary An organisation
         * @param {CreateOrganisation} [CreateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganisations(CreateOrganisation?: CreateOrganisation, options?: any): AxiosPromise<Organisation> {
            return localVarFp.createOrganisations(CreateOrganisation, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  organisation
         * @summary Delete  organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganisationId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteOrganisationId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisationId(id: number, options?: any): AxiosPromise<Organisation> {
            return localVarFp.getOrganisationId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Organisations
         * @summary A page of Organisations
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganisations(page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<OrganisationsOnPage> {
            return localVarFp.getOrganisations(page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * An organisation
         * @summary An organisation
         * @param {number} id Unique id of the item
         * @param {UpdateOrganisation} [UpdateOrganisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganisationId(id: number, UpdateOrganisation?: UpdateOrganisation, options?: any): AxiosPromise<Organisation> {
            return localVarFp.updateOrganisationId(id, UpdateOrganisation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * An organisation
     * @summary An organisation
     * @param {CreateOrganisation} [CreateOrganisation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public createOrganisations(CreateOrganisation?: CreateOrganisation, options?: RawAxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).createOrganisations(CreateOrganisation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  organisation
     * @summary Delete  organisation
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public deleteOrganisationId(id: number, options?: RawAxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).deleteOrganisationId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An organisation
     * @summary An organisation
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisationId(id: number, options?: RawAxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisationId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Organisations
     * @summary A page of Organisations
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public getOrganisations(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).getOrganisations(page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An organisation
     * @summary An organisation
     * @param {number} id Unique id of the item
     * @param {UpdateOrganisation} [UpdateOrganisation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public updateOrganisationId(id: number, UpdateOrganisation?: UpdateOrganisation, options?: RawAxiosRequestConfig) {
        return OrganisationApiFp(this.configuration).updateOrganisationId(id, UpdateOrganisation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlayerApi - axios parameter creator
 * @export
 */
export const PlayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A player
         * @summary A player
         * @param {CreatePlayer} [CreatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlayers: async (CreatePlayer?: CreatePlayer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreatePlayer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  player
         * @summary Delete  player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayerId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePlayerId', 'id', id)
            const localVarPath = `/players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlayerId', 'id', id)
            const localVarPath = `/players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers: async (page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerscreenings: async (page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/players/screenings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {UpdatePlayer} [UpdatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerId: async (id: number, UpdatePlayer?: UpdatePlayer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePlayerId', 'id', id)
            const localVarPath = `/players/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdatePlayer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} fromPlayerId Records will be moved from fromPlayerId to id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerIdMergeFromFromPlayerId: async (id: number, fromPlayerId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePlayerIdMergeFromFromPlayerId', 'id', id)
            // verify required parameter 'fromPlayerId' is not null or undefined
            assertParamExists('updatePlayerIdMergeFromFromPlayerId', 'fromPlayerId', fromPlayerId)
            const localVarPath = `/players/{id}/merge-from/{fromPlayerId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fromPlayerId"}}`, encodeURIComponent(String(fromPlayerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} organisationId The org to move them to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerIdMoveToOrganisationId: async (id: number, organisationId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePlayerIdMoveToOrganisationId', 'id', id)
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('updatePlayerIdMoveToOrganisationId', 'organisationId', organisationId)
            const localVarPath = `/players/{id}/move-to/{organisationId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerApi - functional programming interface
 * @export
 */
export const PlayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlayerApiAxiosParamCreator(configuration)
    return {
        /**
         * A player
         * @summary A player
         * @param {CreatePlayer} [CreatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlayers(CreatePlayer?: CreatePlayer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlayers(CreatePlayer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.createPlayers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  player
         * @summary Delete  player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlayerId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlayerId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.deletePlayerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.getPlayerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayers(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayersOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayers(page, pageSize, search, sort, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.getPlayers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlayerscreenings(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlayersWithScreeningOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlayerscreenings(page, pageSize, search, sort, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.getPlayerscreenings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {UpdatePlayer} [UpdatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlayerId(id: number, UpdatePlayer?: UpdatePlayer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Player>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlayerId(id, UpdatePlayer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.updatePlayerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} fromPlayerId Records will be moved from fromPlayerId to id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlayerIdMergeFromFromPlayerId(id: number, fromPlayerId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlayerIdMergeFromFromPlayerId(id, fromPlayerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.updatePlayerIdMergeFromFromPlayerId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} organisationId The org to move them to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlayerIdMoveToOrganisationId(id: number, organisationId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlayerIdMoveToOrganisationId(id, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlayerApi.updatePlayerIdMoveToOrganisationId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlayerApi - factory interface
 * @export
 */
export const PlayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlayerApiFp(configuration)
    return {
        /**
         * A player
         * @summary A player
         * @param {CreatePlayer} [CreatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlayers(CreatePlayer?: CreatePlayer, options?: any): AxiosPromise<Player> {
            return localVarFp.createPlayers(CreatePlayer, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  player
         * @summary Delete  player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlayerId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deletePlayerId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerId(id: number, options?: any): AxiosPromise<Player> {
            return localVarFp.getPlayerId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayers(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: any): AxiosPromise<PlayersOnPage> {
            return localVarFp.getPlayers(page, pageSize, search, sort, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Players
         * @summary A page of Players
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only players in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlayerscreenings(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: any): AxiosPromise<PlayersWithScreeningOnPage> {
            return localVarFp.getPlayerscreenings(page, pageSize, search, sort, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A player
         * @summary A player
         * @param {number} id Unique id of the item
         * @param {UpdatePlayer} [UpdatePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerId(id: number, UpdatePlayer?: UpdatePlayer, options?: any): AxiosPromise<Player> {
            return localVarFp.updatePlayerId(id, UpdatePlayer, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} fromPlayerId Records will be moved from fromPlayerId to id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerIdMergeFromFromPlayerId(id: number, fromPlayerId: number, options?: any): AxiosPromise<string> {
            return localVarFp.updatePlayerIdMergeFromFromPlayerId(id, fromPlayerId, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {number} organisationId The org to move them to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlayerIdMoveToOrganisationId(id: number, organisationId: number, options?: any): AxiosPromise<string> {
            return localVarFp.updatePlayerIdMoveToOrganisationId(id, organisationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerApi - object-oriented interface
 * @export
 * @class PlayerApi
 * @extends {BaseAPI}
 */
export class PlayerApi extends BaseAPI {
    /**
     * A player
     * @summary A player
     * @param {CreatePlayer} [CreatePlayer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public createPlayers(CreatePlayer?: CreatePlayer, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).createPlayers(CreatePlayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  player
     * @summary Delete  player
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public deletePlayerId(id: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).deletePlayerId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A player
     * @summary A player
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayerId(id: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).getPlayerId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Players
     * @summary A page of Players
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [organisationId] Show only players in this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayers(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).getPlayers(page, pageSize, search, sort, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Players
     * @summary A page of Players
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [organisationId] Show only players in this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public getPlayerscreenings(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).getPlayerscreenings(page, pageSize, search, sort, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A player
     * @summary A player
     * @param {number} id Unique id of the item
     * @param {UpdatePlayer} [UpdatePlayer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public updatePlayerId(id: number, UpdatePlayer?: UpdatePlayer, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).updatePlayerId(id, UpdatePlayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {number} fromPlayerId Records will be moved from fromPlayerId to id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public updatePlayerIdMergeFromFromPlayerId(id: number, fromPlayerId: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).updatePlayerIdMergeFromFromPlayerId(id, fromPlayerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {number} organisationId The org to move them to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerApi
     */
    public updatePlayerIdMoveToOrganisationId(id: number, organisationId: number, options?: RawAxiosRequestConfig) {
        return PlayerApiFp(this.configuration).updatePlayerIdMoveToOrganisationId(id, organisationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get the screening details for the doctor or specialist to review
         * @summary get the screening details for the doctor or specialist to review
         * @param {number} id Unique id of the item
         * @param {ReferralRequest} [ReferralRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralId: async (id: number, ReferralRequest?: ReferralRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createReferralId', 'id', id)
            const localVarPath = `/referral/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ReferralRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * doctor completes their review and maybe refers to specialist(s)
         * @summary doctor completes their review and maybe refers to specialist(s)
         * @param {number} id Unique id of the item
         * @param {DoctorReview} [DoctorReview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralIdDoctor: async (id: number, DoctorReview?: DoctorReview, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createReferralIdDoctor', 'id', id)
            const localVarPath = `/referral/{id}/doctor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(DoctorReview, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration)
    return {
        /**
         * get the screening details for the doctor or specialist to review
         * @summary get the screening details for the doctor or specialist to review
         * @param {number} id Unique id of the item
         * @param {ReferralRequest} [ReferralRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferralId(id: number, ReferralRequest?: ReferralRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferralId(id, ReferralRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferralApi.createReferralId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * doctor completes their review and maybe refers to specialist(s)
         * @summary doctor completes their review and maybe refers to specialist(s)
         * @param {number} id Unique id of the item
         * @param {DoctorReview} [DoctorReview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReferralIdDoctor(id: number, DoctorReview?: DoctorReview, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReferralIdDoctor(id, DoctorReview, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReferralApi.createReferralIdDoctor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiFp(configuration)
    return {
        /**
         * get the screening details for the doctor or specialist to review
         * @summary get the screening details for the doctor or specialist to review
         * @param {number} id Unique id of the item
         * @param {ReferralRequest} [ReferralRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralId(id: number, ReferralRequest?: ReferralRequest, options?: any): AxiosPromise<ReferralResponse> {
            return localVarFp.createReferralId(id, ReferralRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * doctor completes their review and maybe refers to specialist(s)
         * @summary doctor completes their review and maybe refers to specialist(s)
         * @param {number} id Unique id of the item
         * @param {DoctorReview} [DoctorReview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReferralIdDoctor(id: number, DoctorReview?: DoctorReview, options?: any): AxiosPromise<string> {
            return localVarFp.createReferralIdDoctor(id, DoctorReview, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
    /**
     * get the screening details for the doctor or specialist to review
     * @summary get the screening details for the doctor or specialist to review
     * @param {number} id Unique id of the item
     * @param {ReferralRequest} [ReferralRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public createReferralId(id: number, ReferralRequest?: ReferralRequest, options?: RawAxiosRequestConfig) {
        return ReferralApiFp(this.configuration).createReferralId(id, ReferralRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * doctor completes their review and maybe refers to specialist(s)
     * @summary doctor completes their review and maybe refers to specialist(s)
     * @param {number} id Unique id of the item
     * @param {DoctorReview} [DoctorReview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public createReferralIdDoctor(id: number, DoctorReview?: DoctorReview, options?: RawAxiosRequestConfig) {
        return ReferralApiFp(this.configuration).createReferralIdDoctor(id, DoctorReview, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScoringGuideApi - axios parameter creator
 * @export
 */
export const ScoringGuideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete  scoring-guide
         * @summary Delete  scoring-guide
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScoringGuideName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteScoringGuideName', 'name', name)
            const localVarPath = `/scoring-guide/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScoringGuide: async (page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scoring-guide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceScoringGuideName: async (name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceScoringGuideName', 'name', name)
            const localVarPath = `/scoring-guide/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScoringGuideApi - functional programming interface
 * @export
 */
export const ScoringGuideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScoringGuideApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete  scoring-guide
         * @summary Delete  scoring-guide
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScoringGuideName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScoringGuideName(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScoringGuideApi.deleteScoringGuideName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScoringGuide(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScoringGuide(page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScoringGuideApi.getScoringGuide']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceScoringGuideName(name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceScoringGuideName(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScoringGuideApi.replaceScoringGuideName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScoringGuideApi - factory interface
 * @export
 */
export const ScoringGuideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScoringGuideApiFp(configuration)
    return {
        /**
         * Delete  scoring-guide
         * @summary Delete  scoring-guide
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScoringGuideName(name: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteScoringGuideName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScoringGuide(page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<FilesOnPage> {
            return localVarFp.getScoringGuide(page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceScoringGuideName(name: string, options?: any): AxiosPromise<S3FileUpload> {
            return localVarFp.replaceScoringGuideName(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScoringGuideApi - object-oriented interface
 * @export
 * @class ScoringGuideApi
 * @extends {BaseAPI}
 */
export class ScoringGuideApi extends BaseAPI {
    /**
     * Delete  scoring-guide
     * @summary Delete  scoring-guide
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoringGuideApi
     */
    public deleteScoringGuideName(name: string, options?: RawAxiosRequestConfig) {
        return ScoringGuideApiFp(this.configuration).deleteScoringGuideName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Files
     * @summary A page of Files
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoringGuideApi
     */
    public getScoringGuide(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return ScoringGuideApiFp(this.configuration).getScoringGuide(page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows uploading a file to a known name and location in S3
     * @summary Allows uploading a file to a known name and location in S3
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoringGuideApi
     */
    public replaceScoringGuideName(name: string, options?: RawAxiosRequestConfig) {
        return ScoringGuideApiFp(this.configuration).replaceScoringGuideName(name, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScreeningApi - axios parameter creator
 * @export
 */
export const ScreeningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdEmailPlayerSecret: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdEmailPlayerSecret', 'id', id)
            const localVarPath = `/screenings/{id}/email-player-secret`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * generate a new player secret and send it to the player by email
         * @summary generate a new player secret and send it to the player by email
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdRefreshPlayerSecret: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdRefreshPlayerSecret', 'id', id)
            const localVarPath = `/screenings/{id}/refresh-player-secret`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendDoctorEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdSendDoctorEmail', 'id', id)
            const localVarPath = `/screenings/{id}/send-doctor-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendMeetingLink: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdSendMeetingLink', 'id', id)
            const localVarPath = `/screenings/{id}/send-meeting-link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendRetestLink: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdSendRetestLink', 'id', id)
            const localVarPath = `/screenings/{id}/send-retest-link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendSupporterEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdSendSupporterEmail', 'id', id)
            const localVarPath = `/screenings/{id}/send-supporter-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSmsPlayerSecret: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdSmsPlayerSecret', 'id', id)
            const localVarPath = `/screenings/{id}/sms-player-secret`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * when a screening is locked out by too many failed player secret codes
         * @summary when a screening is locked out by too many failed player secret codes
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdUnlock: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScreeningIdUnlock', 'id', id)
            const localVarPath = `/screenings/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {CreateScreening} [CreateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreenings: async (CreateScreening?: CreateScreening, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/screenings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateScreening, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  screening
         * @summary Delete  screening
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScreeningId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScreeningId', 'id', id)
            const localVarPath = `/screenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  screenings file
         * @summary Delete  screenings file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScreeningIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScreeningIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteScreeningIdFilesName', 'name', name)
            const localVarPath = `/screenings/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScreeningId', 'id', id)
            const localVarPath = `/screenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdFiles: async (id: number, page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScreeningIdFiles', 'id', id)
            const localVarPath = `/screenings/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScreeningIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getScreeningIdFilesName', 'name', name)
            const localVarPath = `/screenings/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * admin can login as FMP / specialist / supporter
         * @summary admin can login as FMP / specialist / supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdReferralSecrets: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScreeningIdReferralSecrets', 'id', id)
            const localVarPath = `/screenings/{id}/referral-secrets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * polled every few seconds by the player during an bhp assessment
         * @summary polled every few seconds by the player during an bhp assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdState: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScreeningIdState', 'id', id)
            const localVarPath = `/screenings/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Screenings
         * @summary A page of Screenings
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [assessorId] Show only this assessor
         * @param {number} [organisationId] Show only this organisation
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {string} [bhpCompletedAt] Show screenings with bhp or retest assessments completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreenings: async (page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, assessorId?: number, organisationId?: number, completedAt?: string, bhpCompletedAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/screenings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (playerId !== undefined) {
                localVarQueryParameter['playerId'] = playerId;
            }

            if (assessorId !== undefined) {
                localVarQueryParameter['assessorId'] = assessorId;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }

            if (completedAt !== undefined) {
                localVarQueryParameter['completedAt'] = completedAt;
            }

            if (bhpCompletedAt !== undefined) {
                localVarQueryParameter['bhpCompletedAt'] = bhpCompletedAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceScreeningIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceScreeningIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceScreeningIdFilesName', 'name', name)
            const localVarPath = `/screenings/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {UpdateScreening} [UpdateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScreeningId: async (id: number, UpdateScreening?: UpdateScreening, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScreeningId', 'id', id)
            const localVarPath = `/screenings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateScreening, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * bhp calls this to update the state
         * @summary bhp calls this to update the state
         * @param {number} id Unique id of the item
         * @param {ScreeningState} [ScreeningState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScreeningIdState: async (id: number, ScreeningState?: ScreeningState, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScreeningIdState', 'id', id)
            const localVarPath = `/screenings/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ScreeningState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScreeningApi - functional programming interface
 * @export
 */
export const ScreeningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScreeningApiAxiosParamCreator(configuration)
    return {
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdEmailPlayerSecret(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdEmailPlayerSecret(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdEmailPlayerSecret']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * generate a new player secret and send it to the player by email
         * @summary generate a new player secret and send it to the player by email
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdRefreshPlayerSecret(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningWithDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdRefreshPlayerSecret(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdRefreshPlayerSecret']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdSendDoctorEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdSendDoctorEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdSendDoctorEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdSendMeetingLink(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdSendMeetingLink(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdSendMeetingLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdSendRetestLink(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdSendRetestLink(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdSendRetestLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdSendSupporterEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdSendSupporterEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdSendSupporterEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdSmsPlayerSecret(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdSmsPlayerSecret(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdSmsPlayerSecret']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * when a screening is locked out by too many failed player secret codes
         * @summary when a screening is locked out by too many failed player secret codes
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreeningIdUnlock(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreeningIdUnlock(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreeningIdUnlock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {CreateScreening} [CreateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScreenings(CreateScreening?: CreateScreening, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningWithDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScreenings(CreateScreening, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.createScreenings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  screening
         * @summary Delete  screening
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScreeningId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScreeningId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.deleteScreeningId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  screenings file
         * @summary Delete  screenings file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScreeningIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.deleteScreeningIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreeningId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningWithDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreeningId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreeningId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreeningIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreeningIdFiles(id, page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreeningIdFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreeningIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreeningIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * admin can login as FMP / specialist / supporter
         * @summary admin can login as FMP / specialist / supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreeningIdReferralSecrets(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralSecrets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreeningIdReferralSecrets(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreeningIdReferralSecrets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * polled every few seconds by the player during an bhp assessment
         * @summary polled every few seconds by the player during an bhp assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreeningIdState(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreeningIdState(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreeningIdState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Screenings
         * @summary A page of Screenings
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [assessorId] Show only this assessor
         * @param {number} [organisationId] Show only this organisation
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {string} [bhpCompletedAt] Show screenings with bhp or retest assessments completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScreenings(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, assessorId?: number, organisationId?: number, completedAt?: string, bhpCompletedAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScreenings(page, pageSize, search, sort, playerId, assessorId, organisationId, completedAt, bhpCompletedAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.getScreenings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceScreeningIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.replaceScreeningIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {UpdateScreening} [UpdateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScreeningId(id: number, UpdateScreening?: UpdateScreening, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreeningWithDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScreeningId(id, UpdateScreening, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.updateScreeningId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * bhp calls this to update the state
         * @summary bhp calls this to update the state
         * @param {number} id Unique id of the item
         * @param {ScreeningState} [ScreeningState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScreeningIdState(id: number, ScreeningState?: ScreeningState, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScreeningIdState(id, ScreeningState, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScreeningApi.updateScreeningIdState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScreeningApi - factory interface
 * @export
 */
export const ScreeningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScreeningApiFp(configuration)
    return {
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdEmailPlayerSecret(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdEmailPlayerSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * generate a new player secret and send it to the player by email
         * @summary generate a new player secret and send it to the player by email
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdRefreshPlayerSecret(id: number, options?: any): AxiosPromise<ScreeningWithDetail> {
            return localVarFp.createScreeningIdRefreshPlayerSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendDoctorEmail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdSendDoctorEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendMeetingLink(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdSendMeetingLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendRetestLink(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdSendRetestLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSendSupporterEmail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdSendSupporterEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdSmsPlayerSecret(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdSmsPlayerSecret(id, options).then((request) => request(axios, basePath));
        },
        /**
         * when a screening is locked out by too many failed player secret codes
         * @summary when a screening is locked out by too many failed player secret codes
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreeningIdUnlock(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createScreeningIdUnlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {CreateScreening} [CreateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScreenings(CreateScreening?: CreateScreening, options?: any): AxiosPromise<ScreeningWithDetail> {
            return localVarFp.createScreenings(CreateScreening, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  screening
         * @summary Delete  screening
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScreeningId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteScreeningId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  screenings file
         * @summary Delete  screenings file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScreeningIdFilesName(id: number, name: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteScreeningIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningId(id: number, options?: any): AxiosPromise<ScreeningWithDetail> {
            return localVarFp.getScreeningId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<FilesOnPage> {
            return localVarFp.getScreeningIdFiles(id, page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdFilesName(id: number, name: string, options?: any): AxiosPromise<FileInformation> {
            return localVarFp.getScreeningIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * admin can login as FMP / specialist / supporter
         * @summary admin can login as FMP / specialist / supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdReferralSecrets(id: number, options?: any): AxiosPromise<ReferralSecrets> {
            return localVarFp.getScreeningIdReferralSecrets(id, options).then((request) => request(axios, basePath));
        },
        /**
         * polled every few seconds by the player during an bhp assessment
         * @summary polled every few seconds by the player during an bhp assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreeningIdState(id: number, options?: any): AxiosPromise<ScreeningState> {
            return localVarFp.getScreeningIdState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Screenings
         * @summary A page of Screenings
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [playerId] Show only this player
         * @param {number} [assessorId] Show only this assessor
         * @param {number} [organisationId] Show only this organisation
         * @param {string} [completedAt] Show screenings completed this month eg 2021-01
         * @param {string} [bhpCompletedAt] Show screenings with bhp or retest assessments completed this month eg 2021-01
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScreenings(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, assessorId?: number, organisationId?: number, completedAt?: string, bhpCompletedAt?: string, options?: any): AxiosPromise<ScreeningsOnPage> {
            return localVarFp.getScreenings(page, pageSize, search, sort, playerId, assessorId, organisationId, completedAt, bhpCompletedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceScreeningIdFilesName(id: number, name: string, options?: any): AxiosPromise<S3FileUpload> {
            return localVarFp.replaceScreeningIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * An screening that collects assessments and referrals together
         * @summary An screening that collects assessments and referrals together
         * @param {number} id Unique id of the item
         * @param {UpdateScreening} [UpdateScreening] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScreeningId(id: number, UpdateScreening?: UpdateScreening, options?: any): AxiosPromise<ScreeningWithDetail> {
            return localVarFp.updateScreeningId(id, UpdateScreening, options).then((request) => request(axios, basePath));
        },
        /**
         * bhp calls this to update the state
         * @summary bhp calls this to update the state
         * @param {number} id Unique id of the item
         * @param {ScreeningState} [ScreeningState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScreeningIdState(id: number, ScreeningState?: ScreeningState, options?: any): AxiosPromise<string> {
            return localVarFp.updateScreeningIdState(id, ScreeningState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScreeningApi - object-oriented interface
 * @export
 * @class ScreeningApi
 * @extends {BaseAPI}
 */
export class ScreeningApi extends BaseAPI {
    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdEmailPlayerSecret(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdEmailPlayerSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * generate a new player secret and send it to the player by email
     * @summary generate a new player secret and send it to the player by email
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdRefreshPlayerSecret(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdRefreshPlayerSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdSendDoctorEmail(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdSendDoctorEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdSendMeetingLink(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdSendMeetingLink(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdSendRetestLink(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdSendRetestLink(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdSendSupporterEmail(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdSendSupporterEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdSmsPlayerSecret(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdSmsPlayerSecret(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * when a screening is locked out by too many failed player secret codes
     * @summary when a screening is locked out by too many failed player secret codes
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreeningIdUnlock(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreeningIdUnlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An screening that collects assessments and referrals together
     * @summary An screening that collects assessments and referrals together
     * @param {CreateScreening} [CreateScreening] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public createScreenings(CreateScreening?: CreateScreening, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).createScreenings(CreateScreening, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  screening
     * @summary Delete  screening
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public deleteScreeningId(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).deleteScreeningId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  screenings file
     * @summary Delete  screenings file
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public deleteScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).deleteScreeningIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An screening that collects assessments and referrals together
     * @summary An screening that collects assessments and referrals together
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreeningId(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreeningId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Files
     * @summary A page of Files
     * @param {number} id Unique id of the item
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreeningIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreeningIdFiles(id, page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A file that has been uploaded to S3
     * @summary A file that has been uploaded to S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreeningIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * admin can login as FMP / specialist / supporter
     * @summary admin can login as FMP / specialist / supporter
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreeningIdReferralSecrets(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreeningIdReferralSecrets(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * polled every few seconds by the player during an bhp assessment
     * @summary polled every few seconds by the player during an bhp assessment
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreeningIdState(id: number, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreeningIdState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Screenings
     * @summary A page of Screenings
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [playerId] Show only this player
     * @param {number} [assessorId] Show only this assessor
     * @param {number} [organisationId] Show only this organisation
     * @param {string} [completedAt] Show screenings completed this month eg 2021-01
     * @param {string} [bhpCompletedAt] Show screenings with bhp or retest assessments completed this month eg 2021-01
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public getScreenings(page?: number, pageSize?: number, search?: string, sort?: string, playerId?: number, assessorId?: number, organisationId?: number, completedAt?: string, bhpCompletedAt?: string, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).getScreenings(page, pageSize, search, sort, playerId, assessorId, organisationId, completedAt, bhpCompletedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows uploading a file to a known name and location in S3
     * @summary Allows uploading a file to a known name and location in S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public replaceScreeningIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).replaceScreeningIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An screening that collects assessments and referrals together
     * @summary An screening that collects assessments and referrals together
     * @param {number} id Unique id of the item
     * @param {UpdateScreening} [UpdateScreening] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public updateScreeningId(id: number, UpdateScreening?: UpdateScreening, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).updateScreeningId(id, UpdateScreening, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * bhp calls this to update the state
     * @summary bhp calls this to update the state
     * @param {number} id Unique id of the item
     * @param {ScreeningState} [ScreeningState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScreeningApi
     */
    public updateScreeningIdState(id: number, ScreeningState?: ScreeningState, options?: RawAxiosRequestConfig) {
        return ScreeningApiFp(this.configuration).updateScreeningIdState(id, ScreeningState, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServerApi - axios parameter creator
 * @export
 */
export const ServerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete  server cron
         * @summary Delete  server cron
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerCronId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteServerCronId', 'id', id)
            const localVarPath = `/server/cron/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Cron Jobs
         * @summary A page of Cron Jobs
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerCron: async (page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/server/cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerCronId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getServerCronId', 'id', id)
            const localVarPath = `/server/cron/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if the server is healthy
         * @summary Check if the server is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/server/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {UpdateCronJob} [UpdateCronJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServerCronId: async (id: number, UpdateCronJob?: UpdateCronJob, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServerCronId', 'id', id)
            const localVarPath = `/server/cron/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateCronJob, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServerApi - functional programming interface
 * @export
 */
export const ServerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServerApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete  server cron
         * @summary Delete  server cron
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServerCronId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServerCronId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServerApi.deleteServerCronId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Cron Jobs
         * @summary A page of Cron Jobs
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServerCron(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJobsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServerCron(page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServerApi.getServerCron']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServerCronId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServerCronId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServerApi.getServerCronId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Check if the server is healthy
         * @summary Check if the server is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServerHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerHealth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServerHealth(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServerApi.getServerHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {UpdateCronJob} [UpdateCronJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServerCronId(id: number, UpdateCronJob?: UpdateCronJob, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CronJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServerCronId(id, UpdateCronJob, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServerApi.updateServerCronId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServerApi - factory interface
 * @export
 */
export const ServerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServerApiFp(configuration)
    return {
        /**
         * Delete  server cron
         * @summary Delete  server cron
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServerCronId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteServerCronId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Cron Jobs
         * @summary A page of Cron Jobs
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerCron(page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<CronJobsOnPage> {
            return localVarFp.getServerCron(page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerCronId(id: number, options?: any): AxiosPromise<CronJob> {
            return localVarFp.getServerCronId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if the server is healthy
         * @summary Check if the server is healthy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerHealth(options?: any): AxiosPromise<ServerHealth> {
            return localVarFp.getServerHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * A cron job / background task
         * @summary A cron job / background task
         * @param {number} id Unique id of the item
         * @param {UpdateCronJob} [UpdateCronJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServerCronId(id: number, UpdateCronJob?: UpdateCronJob, options?: any): AxiosPromise<CronJob> {
            return localVarFp.updateServerCronId(id, UpdateCronJob, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServerApi - object-oriented interface
 * @export
 * @class ServerApi
 * @extends {BaseAPI}
 */
export class ServerApi extends BaseAPI {
    /**
     * Delete  server cron
     * @summary Delete  server cron
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public deleteServerCronId(id: number, options?: RawAxiosRequestConfig) {
        return ServerApiFp(this.configuration).deleteServerCronId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Cron Jobs
     * @summary A page of Cron Jobs
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public getServerCron(page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return ServerApiFp(this.configuration).getServerCron(page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A cron job / background task
     * @summary A cron job / background task
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public getServerCronId(id: number, options?: RawAxiosRequestConfig) {
        return ServerApiFp(this.configuration).getServerCronId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if the server is healthy
     * @summary Check if the server is healthy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public getServerHealth(options?: RawAxiosRequestConfig) {
        return ServerApiFp(this.configuration).getServerHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A cron job / background task
     * @summary A cron job / background task
     * @param {number} id Unique id of the item
     * @param {UpdateCronJob} [UpdateCronJob] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServerApi
     */
    public updateServerCronId(id: number, UpdateCronJob?: UpdateCronJob, options?: RawAxiosRequestConfig) {
        return ServerApiFp(this.configuration).updateServerCronId(id, UpdateCronJob, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpecialistApi - axios parameter creator
 * @export
 */
export const SpecialistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {CreateSpecialist} [CreateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpecialists: async (CreateSpecialist?: CreateSpecialist, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specialists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateSpecialist, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  specialist
         * @summary Delete  specialist
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecialistId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSpecialistId', 'id', id)
            const localVarPath = `/specialists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialistId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSpecialistId', 'id', id)
            const localVarPath = `/specialists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Specialists
         * @summary A page of Specialists
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialists: async (page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/specialists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {UpdateSpecialist} [UpdateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecialistId: async (id: number, UpdateSpecialist?: UpdateSpecialist, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSpecialistId', 'id', id)
            const localVarPath = `/specialists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateSpecialist, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialistApi - functional programming interface
 * @export
 */
export const SpecialistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialistApiAxiosParamCreator(configuration)
    return {
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {CreateSpecialist} [CreateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSpecialists(CreateSpecialist?: CreateSpecialist, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Specialist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSpecialists(CreateSpecialist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpecialistApi.createSpecialists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  specialist
         * @summary Delete  specialist
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSpecialistId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSpecialistId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpecialistApi.deleteSpecialistId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialistId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Specialist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialistId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpecialistApi.getSpecialistId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Specialists
         * @summary A page of Specialists
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialists(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialistsOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialists(page, pageSize, search, sort, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpecialistApi.getSpecialists']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {UpdateSpecialist} [UpdateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpecialistId(id: number, UpdateSpecialist?: UpdateSpecialist, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Specialist>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpecialistId(id, UpdateSpecialist, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpecialistApi.updateSpecialistId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SpecialistApi - factory interface
 * @export
 */
export const SpecialistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialistApiFp(configuration)
    return {
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {CreateSpecialist} [CreateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpecialists(CreateSpecialist?: CreateSpecialist, options?: any): AxiosPromise<Specialist> {
            return localVarFp.createSpecialists(CreateSpecialist, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  specialist
         * @summary Delete  specialist
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecialistId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteSpecialistId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialistId(id: number, options?: any): AxiosPromise<Specialist> {
            return localVarFp.getSpecialistId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Specialists
         * @summary A page of Specialists
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {number} [organisationId] Show only this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialists(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: any): AxiosPromise<SpecialistsOnPage> {
            return localVarFp.getSpecialists(page, pageSize, search, sort, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A list of specialists known to the organisation that FMPs can refer players to
         * @summary A list of specialists known to the organisation that FMPs can refer players to
         * @param {number} id Unique id of the item
         * @param {UpdateSpecialist} [UpdateSpecialist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecialistId(id: number, UpdateSpecialist?: UpdateSpecialist, options?: any): AxiosPromise<Specialist> {
            return localVarFp.updateSpecialistId(id, UpdateSpecialist, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialistApi - object-oriented interface
 * @export
 * @class SpecialistApi
 * @extends {BaseAPI}
 */
export class SpecialistApi extends BaseAPI {
    /**
     * A list of specialists known to the organisation that FMPs can refer players to
     * @summary A list of specialists known to the organisation that FMPs can refer players to
     * @param {CreateSpecialist} [CreateSpecialist] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialistApi
     */
    public createSpecialists(CreateSpecialist?: CreateSpecialist, options?: RawAxiosRequestConfig) {
        return SpecialistApiFp(this.configuration).createSpecialists(CreateSpecialist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  specialist
     * @summary Delete  specialist
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialistApi
     */
    public deleteSpecialistId(id: number, options?: RawAxiosRequestConfig) {
        return SpecialistApiFp(this.configuration).deleteSpecialistId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A list of specialists known to the organisation that FMPs can refer players to
     * @summary A list of specialists known to the organisation that FMPs can refer players to
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialistApi
     */
    public getSpecialistId(id: number, options?: RawAxiosRequestConfig) {
        return SpecialistApiFp(this.configuration).getSpecialistId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Specialists
     * @summary A page of Specialists
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {number} [organisationId] Show only this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialistApi
     */
    public getSpecialists(page?: number, pageSize?: number, search?: string, sort?: string, organisationId?: number, options?: RawAxiosRequestConfig) {
        return SpecialistApiFp(this.configuration).getSpecialists(page, pageSize, search, sort, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A list of specialists known to the organisation that FMPs can refer players to
     * @summary A list of specialists known to the organisation that FMPs can refer players to
     * @param {number} id Unique id of the item
     * @param {UpdateSpecialist} [UpdateSpecialist] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialistApi
     */
    public updateSpecialistId(id: number, UpdateSpecialist?: UpdateSpecialist, options?: RawAxiosRequestConfig) {
        return SpecialistApiFp(this.configuration).updateSpecialistId(id, UpdateSpecialist, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupporterApi - axios parameter creator
 * @export
 */
export const SupporterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * supporter accepts the terms and conditions, answers the questionnaire
         * @summary supporter accepts the terms and conditions, answers the questionnaire
         * @param {number} id Unique id of the item
         * @param {SupporterAccept} [SupporterAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupporterIdAccept: async (id: number, SupporterAccept?: SupporterAccept, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSupporterIdAccept', 'id', id)
            const localVarPath = `/supporters/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SupporterAccept, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * supporter declines the terms and conditions
         * @summary supporter declines the terms and conditions
         * @param {number} id Unique id of the item
         * @param {SupporterDecline} [SupporterDecline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupporterIdDecline: async (id: number, SupporterDecline?: SupporterDecline, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSupporterIdDecline', 'id', id)
            const localVarPath = `/supporters/{id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(SupporterDecline, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the screening details for the supporter to review
         * @summary get the screening details for the supporter to review
         * @param {string} token Token emailed to the supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupporterId: async (token: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getSupporterId', 'token', token)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSupporterId', 'id', id)
            const localVarPath = `/supporters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupporterApi - functional programming interface
 * @export
 */
export const SupporterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupporterApiAxiosParamCreator(configuration)
    return {
        /**
         * supporter accepts the terms and conditions, answers the questionnaire
         * @summary supporter accepts the terms and conditions, answers the questionnaire
         * @param {number} id Unique id of the item
         * @param {SupporterAccept} [SupporterAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupporterIdAccept(id: number, SupporterAccept?: SupporterAccept, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupporterIdAccept(id, SupporterAccept, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupporterApi.createSupporterIdAccept']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * supporter declines the terms and conditions
         * @summary supporter declines the terms and conditions
         * @param {number} id Unique id of the item
         * @param {SupporterDecline} [SupporterDecline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSupporterIdDecline(id: number, SupporterDecline?: SupporterDecline, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSupporterIdDecline(id, SupporterDecline, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupporterApi.createSupporterIdDecline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get the screening details for the supporter to review
         * @summary get the screening details for the supporter to review
         * @param {string} token Token emailed to the supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSupporterId(token: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupporterInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSupporterId(token, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupporterApi.getSupporterId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupporterApi - factory interface
 * @export
 */
export const SupporterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupporterApiFp(configuration)
    return {
        /**
         * supporter accepts the terms and conditions, answers the questionnaire
         * @summary supporter accepts the terms and conditions, answers the questionnaire
         * @param {number} id Unique id of the item
         * @param {SupporterAccept} [SupporterAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupporterIdAccept(id: number, SupporterAccept?: SupporterAccept, options?: any): AxiosPromise<string> {
            return localVarFp.createSupporterIdAccept(id, SupporterAccept, options).then((request) => request(axios, basePath));
        },
        /**
         * supporter declines the terms and conditions
         * @summary supporter declines the terms and conditions
         * @param {number} id Unique id of the item
         * @param {SupporterDecline} [SupporterDecline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupporterIdDecline(id: number, SupporterDecline?: SupporterDecline, options?: any): AxiosPromise<string> {
            return localVarFp.createSupporterIdDecline(id, SupporterDecline, options).then((request) => request(axios, basePath));
        },
        /**
         * get the screening details for the supporter to review
         * @summary get the screening details for the supporter to review
         * @param {string} token Token emailed to the supporter
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupporterId(token: string, id: number, options?: any): AxiosPromise<SupporterInformation> {
            return localVarFp.getSupporterId(token, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupporterApi - object-oriented interface
 * @export
 * @class SupporterApi
 * @extends {BaseAPI}
 */
export class SupporterApi extends BaseAPI {
    /**
     * supporter accepts the terms and conditions, answers the questionnaire
     * @summary supporter accepts the terms and conditions, answers the questionnaire
     * @param {number} id Unique id of the item
     * @param {SupporterAccept} [SupporterAccept] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupporterApi
     */
    public createSupporterIdAccept(id: number, SupporterAccept?: SupporterAccept, options?: RawAxiosRequestConfig) {
        return SupporterApiFp(this.configuration).createSupporterIdAccept(id, SupporterAccept, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * supporter declines the terms and conditions
     * @summary supporter declines the terms and conditions
     * @param {number} id Unique id of the item
     * @param {SupporterDecline} [SupporterDecline] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupporterApi
     */
    public createSupporterIdDecline(id: number, SupporterDecline?: SupporterDecline, options?: RawAxiosRequestConfig) {
        return SupporterApiFp(this.configuration).createSupporterIdDecline(id, SupporterDecline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the screening details for the supporter to review
     * @summary get the screening details for the supporter to review
     * @param {string} token Token emailed to the supporter
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupporterApi
     */
    public getSupporterId(token: string, id: number, options?: RawAxiosRequestConfig) {
        return SupporterApiFp(this.configuration).getSupporterId(token, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @summary send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserIdSendOneClickLogin: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createUserIdSendOneClickLogin', 'id', id)
            const localVarPath = `/users/{id}/send-one-click-login`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserIdSendRegistrationEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createUserIdSendRegistrationEmail', 'id', id)
            const localVarPath = `/users/{id}/send-registration-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A user
         * @summary A user
         * @param {CreateUser} [CreateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers: async (CreateUser?: CreateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  user
         * @summary Delete  user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserId', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * dis-approve a user account creation
         * @summary dis-approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserIdApprove: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserIdApprove', 'id', id)
            const localVarPath = `/users/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete  users file
         * @summary Delete  users file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteUserIdFilesName', 'name', name)
            const localVarPath = `/users/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserId', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdFiles: async (id: number, page?: number, pageSize?: number, search?: string, sort?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserIdFiles', 'id', id)
            const localVarPath = `/users/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getUserIdFilesName', 'name', name)
            const localVarPath = `/users/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Currently logged in
         * @summary Currently logged in
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUserloggedinRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserloggedin: async (page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUserloggedinRole, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/loggedin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A page of Users
         * @summary A page of Users
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUsersRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUsersRole, organisationId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUserIdFilesName: async (id: number, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceUserIdFilesName', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('replaceUserIdFilesName', 'name', name)
            const localVarPath = `/users/{id}/files/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {UpdateUser} [UpdateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserId: async (id: number, UpdateUser?: UpdateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserId', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * approve a user account creation
         * @summary approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdApprove: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserIdApprove', 'id', id)
            const localVarPath = `/users/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * prevent user from logging in
         * @summary prevent user from logging in
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdLock: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserIdLock', 'id', id)
            const localVarPath = `/users/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdUnlock: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserIdUnlock', 'id', id)
            const localVarPath = `/users/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * change my own password
         * @summary change my own password
         * @param {ChangePassword} [ChangePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsermePassword: async (ChangePassword?: ChangePassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ChangePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @summary send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserIdSendOneClickLogin(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserIdSendOneClickLogin(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUserIdSendOneClickLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserIdSendRegistrationEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserIdSendRegistrationEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUserIdSendRegistrationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A user
         * @summary A user
         * @param {CreateUser} [CreateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsers(CreateUser?: CreateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsers(CreateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  user
         * @summary Delete  user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * dis-approve a user account creation
         * @summary dis-approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserIdApprove(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserIdApprove(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserIdApprove']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete  users file
         * @summary Delete  users file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserIdFiles(id, page, pageSize, search, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserIdFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Currently logged in
         * @summary Currently logged in
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUserloggedinRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserloggedin(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUserloggedinRole, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserloggedin(page, pageSize, search, sort, role, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserloggedin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A page of Users
         * @summary A page of Users
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUsersRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUsersRole, organisationId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersOnPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(page, pageSize, search, sort, role, organisationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3FileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceUserIdFilesName(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.replaceUserIdFilesName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {UpdateUser} [UpdateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserId(id: number, UpdateUser?: UpdateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserId(id, UpdateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * approve a user account creation
         * @summary approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserIdApprove(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserIdApprove(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserIdApprove']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * prevent user from logging in
         * @summary prevent user from logging in
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserIdLock(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserIdLock(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserIdLock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserIdUnlock(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserIdUnlock(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserIdUnlock']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * change my own password
         * @summary change my own password
         * @param {ChangePassword} [ChangePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUsermePassword(ChangePassword?: ChangePassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsermePassword(ChangePassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUsermePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @summary send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserIdSendOneClickLogin(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createUserIdSendOneClickLogin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserIdSendRegistrationEmail(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.createUserIdSendRegistrationEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A user
         * @summary A user
         * @param {CreateUser} [CreateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsers(CreateUser?: CreateUser, options?: any): AxiosPromise<User> {
            return localVarFp.createUsers(CreateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  user
         * @summary Delete  user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserId(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * dis-approve a user account creation
         * @summary dis-approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserIdApprove(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserIdApprove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete  users file
         * @summary Delete  users file
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserIdFilesName(id: number, name: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteUserIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserId(id: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUserId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Files
         * @summary A page of Files
         * @param {number} id Unique id of the item
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: any): AxiosPromise<FilesOnPage> {
            return localVarFp.getUserIdFiles(id, page, pageSize, search, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * A file that has been uploaded to S3
         * @summary A file that has been uploaded to S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdFilesName(id: number, name: string, options?: any): AxiosPromise<FileInformation> {
            return localVarFp.getUserIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Currently logged in
         * @summary Currently logged in
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUserloggedinRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserloggedin(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUserloggedinRole, organisationId?: number, options?: any): AxiosPromise<UsersOnPage> {
            return localVarFp.getUserloggedin(page, pageSize, search, sort, role, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * A page of Users
         * @summary A page of Users
         * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
         * @param {number} [pageSize] How many items per page
         * @param {string} [search] Filter the list by things that match this string eg for Players by player name
         * @param {string} [sort] Sort the list by a named set of columns
         * @param {GetUsersRole} [role] Show only users with this role
         * @param {number} [organisationId] Show only users in this organisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUsersRole, organisationId?: number, options?: any): AxiosPromise<UsersOnPage> {
            return localVarFp.getUsers(page, pageSize, search, sort, role, organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows uploading a file to a known name and location in S3
         * @summary Allows uploading a file to a known name and location in S3
         * @param {number} id Unique id of the item
         * @param {string} name File basename eg myImage.png
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUserIdFilesName(id: number, name: string, options?: any): AxiosPromise<S3FileUpload> {
            return localVarFp.replaceUserIdFilesName(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * A user
         * @summary A user
         * @param {number} id Unique id of the item
         * @param {UpdateUser} [UpdateUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserId(id: number, UpdateUser?: UpdateUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateUserId(id, UpdateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * approve a user account creation
         * @summary approve a user account creation
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdApprove(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateUserIdApprove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * prevent user from logging in
         * @summary prevent user from logging in
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdLock(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateUserIdLock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ok
         * @summary ok
         * @param {number} id Unique id of the item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserIdUnlock(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.updateUserIdUnlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * change my own password
         * @summary change my own password
         * @param {ChangePassword} [ChangePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsermePassword(ChangePassword?: ChangePassword, options?: any): AxiosPromise<string> {
            return localVarFp.updateUsermePassword(ChangePassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
     * @summary send an email to the player with a UI link to log in without a password and redirect to the BHP assessment
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserIdSendOneClickLogin(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserIdSendOneClickLogin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserIdSendRegistrationEmail(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserIdSendRegistrationEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A user
     * @summary A user
     * @param {CreateUser} [CreateUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUsers(CreateUser?: CreateUser, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createUsers(CreateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  user
     * @summary Delete  user
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserId(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * dis-approve a user account creation
     * @summary dis-approve a user account creation
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserIdApprove(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserIdApprove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete  users file
     * @summary Delete  users file
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A user
     * @summary A user
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserId(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Files
     * @summary A page of Files
     * @param {number} id Unique id of the item
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserIdFiles(id: number, page?: number, pageSize?: number, search?: string, sort?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserIdFiles(id, page, pageSize, search, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A file that has been uploaded to S3
     * @summary A file that has been uploaded to S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Currently logged in
     * @summary Currently logged in
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {GetUserloggedinRole} [role] Show only users with this role
     * @param {number} [organisationId] Show only users in this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserloggedin(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUserloggedinRole, organisationId?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserloggedin(page, pageSize, search, sort, role, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A page of Users
     * @summary A page of Users
     * @param {number} [page] Which page of results to return (1..n), or 0 for all pages
     * @param {number} [pageSize] How many items per page
     * @param {string} [search] Filter the list by things that match this string eg for Players by player name
     * @param {string} [sort] Sort the list by a named set of columns
     * @param {GetUsersRole} [role] Show only users with this role
     * @param {number} [organisationId] Show only users in this organisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(page?: number, pageSize?: number, search?: string, sort?: string, role?: GetUsersRole, organisationId?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(page, pageSize, search, sort, role, organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows uploading a file to a known name and location in S3
     * @summary Allows uploading a file to a known name and location in S3
     * @param {number} id Unique id of the item
     * @param {string} name File basename eg myImage.png
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public replaceUserIdFilesName(id: number, name: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).replaceUserIdFilesName(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A user
     * @summary A user
     * @param {number} id Unique id of the item
     * @param {UpdateUser} [UpdateUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserId(id: number, UpdateUser?: UpdateUser, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserId(id, UpdateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * approve a user account creation
     * @summary approve a user account creation
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserIdApprove(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserIdApprove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * prevent user from logging in
     * @summary prevent user from logging in
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserIdLock(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserIdLock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ok
     * @summary ok
     * @param {number} id Unique id of the item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserIdUnlock(id: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserIdUnlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * change my own password
     * @summary change my own password
     * @param {ChangePassword} [ChangePassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUsermePassword(ChangePassword?: ChangePassword, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUsermePassword(ChangePassword, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetUserloggedinRole = {
    Player: 'Player',
    Org_Admin: 'Org Admin',
    Brain_Health_Practitioner: 'Brain Health Practitioner',
    Global_Admin: 'Global Admin',
    Clinical_Technical_Manager: 'Clinical Technical Manager'
} as const;
export type GetUserloggedinRole = typeof GetUserloggedinRole[keyof typeof GetUserloggedinRole];
/**
 * @export
 */
export const GetUsersRole = {
    Player: 'Player',
    Org_Admin: 'Org Admin',
    Brain_Health_Practitioner: 'Brain Health Practitioner',
    Global_Admin: 'Global Admin',
    Clinical_Technical_Manager: 'Clinical Technical Manager'
} as const;
export type GetUsersRole = typeof GetUsersRole[keyof typeof GetUsersRole];


