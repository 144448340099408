/**
 * tools to help with translations
 */
import {
  ActivityType, AlgorithmOutcome, AssessmentFlag, Sex, SpecialistReferralStatus, SpecialistType, SupporterRelationship
} from '@/api';
import i18n from '@/plugins/i18n';
import { StepName } from '@/service/Assessment/StepNames';
import { Environment } from '@/service/Environment';
import { AllowedLanguage, devLanguages } from '@/service/Language';
import { camelCase } from 'lodash-es';

export function useTranslation() {
  const {tm, rt, t, te, locale} = i18n.global;

  /**
   * pass in the translation id of the list and get back the list of options
   */
  function listOptions(listId: string): string[] {
    const items = tm(listId);
    // @ts-ignore
    return Object.keys(items).map(key => rt(items[key]));
  }

  function getLanguages() {
    const items = tm('meta.language');
    const result: Record<string, string> = {};
    for (const key in items) {
      if (Environment.isProduction() && devLanguages.includes(key as AllowedLanguage)) continue;
      // @ts-ignore
      result[key] = rt(items[key]);
    }
    return result;
  }

  /**
   * find a translated value in a list and return its index
   */
  function indexOf(value: string | undefined, stepName: StepName): number | undefined {
    if (!value) return undefined;
    const index = value.split(':').at(1);
    if (!index || Number(index) < 0) throw new Error(`value ${ value } not found in translation step.${ stepName }`);
    return Number(index);
  }

  // turn a value like 'arthritisOptions:5' into 'Shoulder'. This is almost the inverse of getOptionValue()
  function getAnswerText(stepName: string, answer: string | undefined | boolean): string | undefined {
    if (typeof answer === 'undefined') return undefined;
    if (typeof answer === 'boolean') return answer ? t('button.yes') : t('button.no');
    if (!answer.includes(':')) return undefined;
    const [question, index] = answer.split(':');
    if (index === undefined) return undefined;
    const listId = `step.${ stepName }.${ question }`;
    return listOptions(listId).at(Number(index));
  }

  function indexGreaterThan(value: string | undefined, listId: StepName, threshold: number): boolean {
    return (indexOf(value, listId) ?? 0) > threshold;
  }

  function getOptionKey(listId: string) {
    return listId.replace(/.*\./, ''); // keep the last part of the key
  }

  // turns eg (step.medicalHistory.arthritisOptions, 5) into 'arthritisOptions:5' for storage in answerJson
  function getOptionValue(listId: string, index: number) {
    return `${ getOptionKey(listId) }:${ index }`;
  }

  function translatedFlag(flag: AssessmentFlag) {
    const id = 'meta.assessmentFlags.' + flag;
    return te(id) ? t(id) : flag;
  }

  function stepTitleId(stepName: StepName | string) {
    return `step.${ stepName }.title`;
  }

  function stepTitle(stepName: StepName | string) {
    return t(stepTitleId(stepName));
  }

  function stepPlayerTitle(stepName: StepName | string) {
    return t(`step.${ stepName }.playerTitle`);
  }

  function stepAbnormal(stepName: StepName | string) {
    return t(`step.${ stepName }.abnormal`);
  }

  function translatedRole(role: string) {
    const id = 'meta.role.' + role;
    if (te(id)) return t(id); // eg orgAdmin
    const id2 = 'meta.role.' + camelCase(role); // eg Org_Admin
    return te(id2) ? t(id2) : role;
  }

  function translatedLanguage(language: string) {
    const id = 'meta.language.' + language;
    return te(id) ? t(id) : language;
  }

  function translatedSex(sex: Sex) {
    const id = 'meta.sex.' + sex;
    return te(id) ? t(id) : sex;
  }

  function translatedRelationship(relationship: SupporterRelationship) {
    const id = 'meta.relationship.' + relationship;
    return te(id) ? t(id) : relationship;
  }

  function translatedActivity(activity: ActivityType) {
    const id = 'meta.activityTypes.' + activity;
    return te(id) ? t(id) : activity;
  }

  function translatedOutcome(risk: AlgorithmOutcome) {
    const id = 'meta.algorithmOutcome.' + risk;
    return te(id) ? t(id) : risk;
  }

  function translatedSpecialistType(specialist: SpecialistType) {
    const id = 'meta.specialistType.' + specialist;
    return te(id) ? t(id) : specialist;
  }

  function translatedSpecialistReferral(status: SpecialistReferralStatus) {
    const id = 'meta.specialistReferral.' + status;
    return te(id) ? t(id) : status;
  }

  return {
    tm, rt, te, t, locale, // from useI18n()
    getLanguages,
    listOptions,
    indexOf,
    indexGreaterThan,
    getOptionValue,
    getAnswerText,
    translatedFlag,
    translatedLanguage,
    translatedRole,
    translatedSex,
    translatedRelationship,
    translatedActivity,
    translatedOutcome,
    translatedSpecialistType,
    translatedSpecialistReferral,
    stepTitle,
    stepTitleId,
    stepPlayerTitle,
    stepAbnormal
  };
}
