/**
 * strictly type and validate environment variables
 */
import process from 'process';

type EnvironmentName = 'production' | 'nonprod' | 'koandev' | 'test' | 'development';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      NODE_ENV: EnvironmentName | undefined;
      BUILD_NUMBER: string | undefined;
      // VUE_ROUTER_MODE: 'hash' | 'history' | 'abstract' | undefined;
      // VUE_ROUTER_BASE: string | undefined;
      // API: string; // url to brain health api for axios
      // SENTRY_DSN: string;
    }
  }
}

const apiEndpoints: { [env in EnvironmentName]: string } = {
  'koandev': 'https://api.koandev.brainhealth-koan.co.nz',
  'nonprod': 'https://api.nonprod.brainhealthservice.rugby',
  'production': 'https://api.brainhealthservice.rugby',
  'development': 'http://localhost:8080',
  'test': 'http://localhost:8080'
};

export const Environment = {
  // these are used to switch things on and off eg as feature flags
  isProduction: () => Environment.getEnvironment() === 'production',
  isUnitTest: () => Environment.getEnvironment() === 'test' || window.location.hostname === 'localhost',
  getEnvironment: () => import.meta.env.MODE ?? 'development',
  getBuildDate: () => BUILD_DATE ?? (new Date()).toISOString(),
  getBuildNumber: () => BUILD_NUMBER ?? '0',
  getSentryDSN: () => import.meta.env.SENTRY_DSN ?? 'https://04609ef5708b43b0a767a255ad0e40dd@o4503915914657792.ingest.sentry.io/4505145710477312',
  getAPI: () => import.meta.env.API ?? apiEndpoints[Environment.getEnvironment() as EnvironmentName] ?? apiEndpoints['development'],
  getAPITimeout: () => Environment.isUnitTest() ? 4000 : 20000
};
