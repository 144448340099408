import { LoginResponse, Role, User } from '@/api';
import { Environment } from '@/service/Environment';
import { destr as jsonParse } from 'destr';
import { defaultLanguage } from '@/service/Language';
import * as Sentry from "@sentry/vue";

const adminRoles: Array<Role> = [Role.Global_Admin, Role.Clinical_Technical_Manager];

export const userIsAdmin = (user: User) => adminRoles.includes(user.role);

export class Session {
  private current: LoginResponse | undefined;
  private impersonating: User | undefined; // impersonation is lost if you refresh the page
  private storageKey = `session${ Environment.getEnvironment() }`;

  constructor() {
    const stored = localStorage.getItem(this.storageKey);
    if (stored) {
      this.current = jsonParse<LoginResponse>(stored);
      Sentry.setUser({id: this.userId});
    }
  }

  private save() {
    // we use session storage so that if the user refreshes the page, they don't have to log in again
    if (this.current) {
      localStorage.setItem(this.storageKey, JSON.stringify(this.current));
    } else {
      localStorage.removeItem(this.storageKey);
    }
  }

  get user() {
    return this.impersonating ?? this.current?.user;
  }

  get accessToken() {
    if (this.current && this.current.accessToken && this.current.accessTokenExpires > Date.now()) {
      return this.current.accessToken;
    }
    return undefined;
  }

  get refreshToken() {
    if (this.current && this.current.refreshToken && this.current.refreshTokenExpires > Date.now()) {
      return this.current.refreshToken;
    }
    return undefined;
  }

  get accessTokenIsValid() {
    return !!this.accessToken;
  }

  get refreshTokenIsValid() {
    return !!this.refreshToken;
  }

  get isLoggedIn() {
    return !!this.refreshToken;
  }

  get isImpersonating() {
    return !!this.impersonating;
  }

  get userId(): number {
    return this.user?.id ?? 0;
  }

  get organisationId(): number | undefined {
    return this.user?.organisationId ?? undefined;
  }

  get userName(): string {
    return [this.user?.firstName, this.user?.lastName].filter(value => !!value).join(' ');
  }

  get language(): string {
    return this.user?.language ?? defaultLanguage;
  }

  // what can the user do
  get canSeeRawData(): boolean {
    return this.user?.role === Role.Clinical_Technical_Manager;
  }

  get canSeeAssessmentFlags(): boolean {
    return this.canSeeRawData;
  }

  get canImpersonate() {
    return !!this?.user && userIsAdmin(this.user);
  }

  get maxIdleMinutes() {
    // BH-144 specifies the idle times for different roles. Bit it was impractical so we have extended it
    if (!this.isLoggedIn) return 0; // disabled
    if (this.isPlayer) return 60 * 12;
    return 60 * 4; // 4 hours for everyone else
  }

  login(token: LoginResponse) {
    this.current = token;
    this.impersonating = undefined;
    this.save();
    Sentry.setUser({id: this.userId});
  }

  logout() {
    this.current = undefined;
    this.impersonating = undefined;
    this.save();
    Sentry.setUser(null);
  }

  updateUser(user: User) {
    // user edited their own account: update it on save
    const existing = this.current?.user;
    if (existing) {
      existing.firstName = user.firstName;
      existing.lastName = user.lastName;
    }
  }

  impersonate(user?: User) {
    if (!this.current) {
      // can only impersonate when already logged in
      return;
    }
    if (user && !this.canImpersonate) {
      // only allow admins to start impersonating
      return;
    }
    this.impersonating = user;
  }

  // tells us what routes this user can access
  private get isPlayer(): boolean {
    return this.user?.role === Role.Player;
  }

  private get isBHP(): boolean {
    return this.user?.role === Role.Brain_Health_Practitioner;
  }

  private get isOrgAdmin(): boolean {
    return this.user?.role === Role.Org_Admin;
  }

  private get isSysAdmin(): boolean {
    return !!this.user && userIsAdmin(this.user);
  }

  getRoleBaseUrl(): string | undefined {
    if (this.isPlayer) return '/player';
    if (this.isBHP) return '/bhp';
    if (this.isOrgAdmin) return '/orgadmin';
    if (this.isSysAdmin) return '/sysadmin';
    return undefined; // not logged in
  }

  linkToPlayer(playerId: number | undefined): string | undefined {
    if (!playerId) return undefined;
    if (this.isPlayer) return '/player';
    if (this.isBHP) return '/bhp/player/' + playerId;
    if (this.isOrgAdmin) return '/orgadmin/player/' + playerId;
    if (this.isSysAdmin) return '/sysadmin/player/' + playerId;
    return undefined;
  }

  linkToUser(userId: number | undefined): string | undefined {
    if (!userId) return undefined;
    if (this.isOrgAdmin) return '/orgadmin/user/' + userId;
    if (this.isSysAdmin) return '/sysadmin/user/' + userId;
    return undefined;
  }

  linkToScreening(screeningId: number | undefined): string | undefined {
    if (!screeningId) return undefined;
    if (this.isOrgAdmin) return '/orgadmin/screening/' + screeningId;
    if (this.isSysAdmin) return '/sysadmin/screening/' + screeningId;
    return undefined;
  }

  linkToOrganisation(orgId: number | undefined): string | undefined {
    if (!orgId) return undefined;
    if (this.isSysAdmin) return '/sysadmin/organisation/' + orgId;
    return undefined;
  }
}
